import React from "react"
import TextPageHeader from "../components/TextPageHeaderBars"
import TextPage from "../components/pageTemplates/TextPage"
import styled from "styled-components"
import {Button,Container, Row, Col} from "react-bootstrap"
import {ButtonColor} from "../components/globalimports/SmartboardConfiguration"
import Lock from "../lock.svg"


const Buttons = styled.div`
  text-align:right;
`;

const MainHeader = styled.h1`
  margin-top: 0.5rem;
  margin-bottom: 0px;
`
const LockContainer = styled.img`
  color:blue;
  width:100%;
`
const TextLine = styled.div`
  margin-top:1rem;
`
export default class MustSubmitDataPage extends React.Component {
  close() {
    if (this.props.close) {
      this.props.close()
    }
  }
  render() {
    const btnStyle = {"backgroundColor":ButtonColor, borderColor:ButtonColor, marginLeft:"1rem"}
    const buttons = <Buttons>{(this.props.buttontexts||[]).map(a=> {return <Button key={a.text} style={btnStyle} onClick={a.action}>{a.text}</Button>})}</Buttons>
    return (
      <TextPage header={<TextPageHeader></TextPageHeader>} halfSizeHeader={<TextPageHeader halfsizemode={true}></TextPageHeader>}>
        <Container style={{padding:"4rem"}}>
          <Row>
            <Col>
              {buttons}
              <hr />
              <MainHeader>SMARTBOARD Seilbahnen</MainHeader>
              <div>
                <TextLine>
                  Liebe Smartboard-Userin, lieber Smartboard-User,
                </TextLine>
                <TextLine>
                  Ihr Smartboard-Zugang ist vorläufig deaktiviert.
                </TextLine>
                <TextLine>
                  Das Smartboard Seilbahnen zeigt die aktuellsten verfügbaren Zahlen, Daten und Fakten zu den Seilbahnen.
                </TextLine>
                <TextLine>
                  Um aktuell zu sein brauchen wir auch Ihre Daten!
                </TextLine>
                <TextLine>
                  Derzeit fehlen im WEBMARK die Daten Ihres Betriebes für die letzte Saison. Bitte geben Sie diese ein.
                </TextLine>
                <TextLine>
                  Gleich nach Eingabe der Daten, wird Ihr Smartboard-Zugang wieder aktiviert.
                </TextLine>
              </div>
              <hr />
              {buttons}
            </Col>
            <Col><LockContainer src={Lock} /></Col>

          </Row>
        </Container>
      </TextPage>)

  }
}
