import React, {useRef, useState} from 'react';

import Errorwraper from "./Errorwraper"

const HtmlChart = (props) => {

    const htmlC = useRef()
    const [value, setValue] = useState(0)
    
    // künstliches Update um die Componente neu zu laden wenn der Ref nicht null ist
    props.hasNull && setTimeout(() => {
        setValue(value => value + 1)}
        , 120);


    return (
        <div ref={htmlC}>
        <Errorwraper hasNull={props.hasNull} parent={htmlC.current} titel={props.categorytitel}></Errorwraper>
        <div style={{backgroundColor: "white", height: "100%", zIndex: "2"}} dangerouslySetInnerHTML={props.html}/>
        </div>
    );
}

// const useForceUpdate = () => {
//     const [value, setValue] = useState(0); // integer state
//     return () => setValue(value => value + 1); // update the state to force render
// }

export default HtmlChart;