import {HeaderBackgroundColor, HeaderFontColor, ManovaLogo, SmartboardLogoWithText} from "./globalimports/SmartboardConfiguration";
import React from "react";
import styled from "styled-components";

const HeaderBar = styled.div`
  background-color:${HeaderBackgroundColor};
  display:flex;
  justify-content:space-between;
  max-height:80px;
  color:${HeaderFontColor};
  padding-bottom:8px;
  padding-top:6px;
  padding-right:${(props) => {
  if (props.mobile) {
    return 0;
  } else {
    return 2.5;
  }
}}vw;
  padding-left:2.5vw;
  position:sticky;
  top:0;
  z-index:5;
`
export default function TextPageHeader(props) {
  if (props.halfsizemode) {
    return (
      <HeaderBar mobile={true}>
        <ManovaLogo/>
      </HeaderBar>
    )
  } else {
    return (
      <HeaderBar>
        <ManovaLogo/>
        <SmartboardLogoWithText/>
      </HeaderBar>
    )
  }
}
