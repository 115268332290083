import React from 'react';
import {
  HeaderBackgroundColor,
  minWidthForSingleLayout as minWidth,
  PageBackgroundColor
} from "../globalimports/SmartboardConfiguration"


import SmartboardFooterBar from "../SmartboardFooterBar"
export default class TextPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      textmode: false,
      halfsizemode: (window.innerWidth < (minWidth))
    }
    this.onResize = this.onResize.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    window.addEventListener("resize", this.onResize)
  }

  render() {
    let borderFormat = {}
    if (!this.state.halfsizemode) {
      borderFormat.paddingLeft = borderFormat.paddingRight = "2.5vw"
      borderFormat.backgroundColor = HeaderBackgroundColor
    } else {
      borderFormat.padding = borderFormat.margin = "0vw"
    }
    return <div style={borderFormat}>
      {this.props.header || ""}
      <div className={"textpage"} style={borderFormat}>
        <div style={{backgroundColor:PageBackgroundColor}}>
          {this.props.children}
        </div>
      </div>
      <SmartboardFooterBar/>
    </div>
  }
  getHeader() {
    if (this.state.halfsizemode) {
      return this.props.halfSizeHeader
    }
    else {
      return this.props.header
    }

  }

  onResize(e) {
    let newState = {}
    newState.halfsizemode = (window.innerWidth < (minWidth))
    this.setState(newState)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize)
  }


}

