import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import {sortableElement} from 'react-sortable-hoc'
import SmartboardChartWrapper from "./SmartboardChartWrapper"
import {LinkHoverColor, dragBackgroundColor, chartOuterMargin as outmargin, minWidthForSingleLayout as minWidth, HoverImage} from "./globalimports/SmartboardConfiguration"
import store from "store";
import download from "downloadjs";
const ReactMarkdownWithHtml = require('react-markdown/with-html')
const DraggableContainer = sortableElement(({children, maxChartItemHeight, className}) => {
  return <DraggableContainerDiv  maxChartItemHeight={maxChartItemHeight} hpwidth={100-2*outmargin} className={"CONTAINER fcFIX fs " + className}>{children}</DraggableContainerDiv>

})
const HSDraggableContainer = sortableElement(({children, minWidth, className}) => {
  return <DraggableContainerDiv halfsizemode pwidth={50 - 2*outmargin} minWidth={minWidth} className={"CONTAINER fcFIX hs " + className}>{children}</DraggableContainerDiv>

})

const ColumnDiv = styled.div`
  flex-direction: column;
  position: absolute;
`

const DateSourcesParagraph = styled.p`
  text-align: right;
  font-size: x-small;
  font-family: Lato;
  width: 100%;
`

const Infobutton = styled.div`
  background-color: rgba(0,0,0,0);
  position: absolute;
  height: 50px;
  width: 50px;
  right:${(props)=>{
    if (!props.halfsizemode) {
      return 5;
    }
    else {
      return 2;
    }
  }}px;
  top:${(props)=>{
    if (!props.halfsizemode) {
      return 25;
    }
    else {
      return 2;
    }
  }}px;
  z-index: 2;
  text-shadow: 2px 2px red;
`

const DraggableContainerDiv = styled.div`
  position: relative;
  width: ${(props) => {
    if(props.halfsizemode) {
      return props.pwidth || 92
    }
    else {
      return 98
    }
  }}%;
  height: ${(props) => {
    if (props.halfsizemode) {
      return "33vh"
    }
    else {
      return /*(props.maxChartItemHeight || 300).toString()  + 'px'*/"auto"
    }
  }};
  min-height: ${(props) => {
    if (props.halfsizemode) {
      return "300px"
    }
    else {
      return "auto"
      
    }
  }};
  min-width:${(props) => {
    if (props.halfsizemode) {
      return minWidth.toString() + "px"
    }
    else {
      return "auto"
    }
  }};
  /*max-width:900px;*/
  
  margin:${(props)=> {
    if (props.halfsizemode) {
      return "20px"
    }
    else {
      return "0px";
    }
  }};
  padding: 0px;
  display:flex;
  flex-direction:${(props) => {
    if (props.halfsizemode) {
      return "row"
    }  
    else {
      return "column"
    }
  }};
  flex-wrap:nowrap;
  justify-content:space-between;
  align-items:stretch;
  align-content:space-betweeen;
`
const RowDiv = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  position: relative;
  align-items:stretch;
  background-color: #f5f5f3;
  width: 100%;
`

const SContainer = styled.div`
  border:${(props)=>{
    if (props.showInfo) {
      return "2px solid " + LinkHoverColor
    } else {
      return "0px"
    }
  }};
  height:${(props)=>{
  if (props.fcType) {
      if (!props.halfsizemode)
      return "45vh"
      else
        return "100%"
    }
    else {
      if (props.halfsizemode) {
        return "100%"
      }
      else {
        return "auto"
      }
    }
  }};
  width:${(props) => {
    if (props.halfsizemode) {
      return props.size || 100
    }
    else {
      return 100
    }
  }}%;
  display:flex;
  margin-right:0px;
  margin-left:${(props) => {
    if (props.halfsizemode) {
      return "20px"
    }
    else {
      return "0px"
    }
  }};
  margin-bottom:${(props) => {
    if (props.halfsizemode) {
      return "20px"
    }
    else {
      return "0px"
    }
  }};
  margin-top:${(props) => {
  if (props.halfsizemode) {
    return "0px"
  }
  else {
    return "20px"
  }
}};
  vertical-align:middle;
  align-self:flex-start;
  background-color:white;
  box-shadow:${(props) => {
    if (props.exportMode || props.showInfo) {
      return "none"
    } else {
      return "10px 10px 5px lightgrey"
    }
  }};
`
// box-shadow: 10px 10px 5px lightgrey;
const Xonehundrediv = styled.div`
  width:100%;
  background-color:${() => {
    return dragBackgroundColor
  }};
`


export default class CategoryPageItem extends React.Component {

  constructor(props) {
    super(props)
    this.state = {}
    this.state.showInfo = false
    let sp = new URLSearchParams(window.location.search);
    let boardid = this.state.boardid = sp.get("id");
    let boardkey = this.state.boardkey = sp.get("key");
    this.state.authkey = store.get(this.lsAccessKey(boardid, boardkey)) || ""
    this.showInfo = this.showInfo.bind(this)
    this.requestExport = this.requestExport.bind(this);
    this.setExportValues = this.setExportValues.bind(this);
  }

  lsAccessKey(boardid, boardkey) {
    return boardid + '_' + boardkey;
  }

  handleFusionchartData (outsidefcData, itemSize) {
    if (!outsidefcData.dataSource) {
      outsidefcData = ({
        "dataSource":outsidefcData,
        "dataFormat":"json",
        "type":"msline"
      })
    }
    let ic = (<SmartboardChartWrapper fcData={outsidefcData} itemSize={itemSize}/>);
    return (<Xonehundrediv><div className={"fcFIX"}>{ic}</div></Xonehundrediv>)
  }

  handleHtmlData (htmlData) {
    if (!htmlData["__html"]) {
      htmlData = {"__html": "set __html property"}
    }
    return <Xonehundrediv><ReactMarkdownWithHtml children={htmlData.__html} allowDangerousHtml/></Xonehundrediv>
  }

  getItemDataAsElements (itemType, itemData, key, itemSize) {
    let el;
    switch(itemType) {
      case "combo":
        const accessKey = "flex-grow"
        const sumsize = itemData.reduce((ac,cv,cin,ar) => {return ac+(cv[accessKey]||1)},0)
        el = itemData.map((v,i,a) => {
          v.data[accessKey] = v[accessKey]
          return (this.getItemDataAsElements(v.type, v.data, key+i.toString(), 100/sumsize * v[accessKey]))
        }, this)
      break;
      case "fusioncharts":
        el = (<SContainer showInfo={this.state.showInfo} fcType={true} halfsizemode={this.props.halfsizemode} key={key} className={"fcCONTAINER fcFIX"} size={itemSize} exportMode={this.props.exportMode}>{this.handleFusionchartData(itemData, itemSize)}</SContainer>)
      break;
      case "nodata":
      case "html":
        el=this.handleHtmlData(itemData)
        el = (<SContainer showInfo={this.state.showInfo} halfsizemode={this.props.halfsizemode} key={key} className={"htmlCONTAINER fcFIX"} size={itemSize} exportMode={this.props.exportMode}>{el}</SContainer>)
      break;
      default:
        el = ""
      break;
    }
    return el
  }

  getInfoTextElements() {
    let sourceData = this.props.item.source
    let multipleSources = sourceData.indexOf(',') !== -1
    let sourceLabel = "Quelle"
    if (multipleSources) {
      sourceLabel = "Quellen"
    }
    let content = (
      <div className={"d-flex flex-column h-100 html_infotext"}>
        <div>
          <button className={"exportbuttoninfo hoverPointer"} onClick={this.requestExport}>
            <img style={{marginRight:"0.5em", width:"1.5em", height:"1.5em"}} alt={"Chart Exportieren"} src={process.env.PUBLIC_URL+"/ic/export-button.svg"}/> <span>Chart exportieren</span>
          </button>
        </div>
        <div><ReactMarkdown children={this.replaceMd(this.props.item.chartinfo)}/></div>
        <div className={"align-text-bottom d-flex align-items-end"} style={{fontSize:"smaller"}}>
          {sourceLabel}: {this.props.item.source || ""}
        </div>
      </div>
    )
    return <SContainer showInfo={this.state.showInfo} halfsizemode={this.props.halfsizemode} className={"htmlCONTAINER fcFIX"} exportMode={this.props.exportMode}>{content}</SContainer>
  }

  showInfo() {
    this.setState({showInfo: !this.state.showInfo})
  }

  requestExport() {
    let fetchurl = process.env.REACT_APP_SMARBOARD_BASEURI + "/getExport"
    let vars = {
      "boardId": this.state.boardid,
      "chartId": this.props.item.id ,
      "categoryId": this.props.item.categoryid,
      "boardKey": this.state.boardkey,
      "authKey": this.state.authkey
    }
    const params = []
    for (let key in vars) {
      params.push(encodeURIComponent(key) + "=" + encodeURIComponent(vars[key]))
    }
    let fetchparams = params.join("&")
    fetchurl = fetchurl + "?" + fetchparams
    let filename = ""
    fetch(fetchurl)
        .then(response => {
          filename = response.headers.get("content-disposition")
          return response.blob()
        }).then(blob => {
      download(blob, filename, "image/png")
    }).catch((err) => console.error(err))
  }

  replaceMd(markdown) {
    return markdown.replace(/\r/g,"\n").replace(/\n\n/g, "\n").replace(/\n/g, "\n\n")
  }
  getContainerClassName() {
    var keep_title_html = ""

    return [this.props.item.categoryid,this.props.item.id,keep_title_html].join(" ")
  }

  setExportValues(item) {
    let chart
    let type
    if (item.type) {
      type = item.type
    }
    if (item.data && item.data.type) {
      type = item.data.type
    }
    if (item.dataSource && item.dataSource.chart) {
      chart = item.dataSource.chart
    } else if (item.data && item.data.dataSource && item.data.dataSource.chart) {
      chart = item.data.dataSource.chart
    } else if (item.chart) {
      chart = item.chart
    } else {
      let newSubCaptionSize = "<style> .html_chart__subtitle.html_chart__subtitle { font-size: 14px; }"
      if (item.__html) {
        item.__html = item.__html.replace("<style>", newSubCaptionSize)
      } else if (item.data.__html) {
        item.data.__html = item.data.__html.replace("<style>", newSubCaptionSize)
      }
    }
    if (chart) {
      if (type && type.includes("column2d")) {
        this.fixDataValues(item)
      }
      chart.subcaptionFontSize = "14"
      chart.showValues = "1"
      if (chart.caption && chart.caption.includes("letzte Woche") && chart.caption.includes("Trend")) {
        chart.showValues = "0"
      }
      if (type === "doughnut2d") {
        if (chart.defaultCenterLabel) {
          chart.showValues = "0"
        }
      }
      if (type && (type.includes("column2d") || type.includes("bar2d"))) {
        chart.minPlotHeightForValue = "15"
        chart.valueBgColor = "#ffffff"
        chart.valueBgAlpha = "40" // 100 ist ganz gefärbt, 0 ist ganz durchsichtig
      }
      // chart.valueFontColor = "#787473"
      if (! this.props.showTitle) {
        if (chart.caption && chart.caption !== "") {
          //delete chart.caption
          chart.caption = ""
        }
        if (chart.subCaption && chart.subCaption !== "") {
          //delete chart.subCaption
          chart.subCaption = ""
        }
      }
    }
  }

  fixDataValues(item) {
    let keys = Object.keys(item)
    // console.log(Object.keys(item))
    var i
    for (i in keys) {
      let item_key = keys[i]
      if (item_key === "displayValue") {
        var value = item[item_key].trim()
        if (value.startsWith("|")) {
          value = value.substring(1)
          value = value.trim()
          item[item_key] = value
        }
      }  else if (Array.isArray(item[item_key]) && (item_key === "dataSource" || item_key === "data" || item_key === "dataset")) {
        var j
        for (j in item[item_key]) {
          this.fixDataValues(item[item_key][j])
        }
      } else if (item_key === "dataSource" || item_key === "data" || item_key === "dataset") {
        this.fixDataValues(item[item_key])
      }
    }
  }

  render () {
    let itemType = this.props.item.type
    let itemData = this.props.item.data
    const infoButtonIconGraph = (<HoverImage style={{"backgroundColor":"rgba(0,0,0,0)"}} doHover={process.env.PUBLIC_URL+"/ic/infobox-hover.svg"} noHover={process.env.PUBLIC_URL+"/ic/infobox-inaktiv.svg"}/>)
    const infoButtonIconInfoText = (<span><img alt={this.props.alt||""} src={process.env.PUBLIC_URL+"/ic/infobox-schließen.svg"}/></span>)
    let infoButton
    if (this.state.showInfo) {
      infoButton = (<Infobutton showInfo={this.state.showInfo} halfsizemode={this.props.halfsizemode} className={"infobutton hoverPointer"} onClick={this.showInfo}>{infoButtonIconInfoText}</Infobutton>)
    } else {
      infoButton = (<Infobutton showInfo={this.state.showInfo} halfsizemode={this.props.halfsizemode} className={"infobutton hoverPointer"} onClick={this.showInfo}>{infoButtonIconGraph}</Infobutton>)
    }
    let content
    if (this.props.exportMode) {
      if (Array.isArray(itemData)) {
        itemData.forEach(function (item, index) {
          this.setExportValues(item)
        }, this)
      } else {
        this.setExportValues(itemData)
      }
    }
    if (this.state.showInfo) {
      content = this.getInfoTextElements()
    } else {
      content = this.getItemDataAsElements(itemType, itemData)
    }
    let container
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0')
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear();
    today = dd + '.' + mm + '.' + yyyy
    const date = "Stand: " + today
    const sources = this.props.item.source
    if (this.props.halfsizemode) {
      if (this.props.exportMode) {
        /*
        <ColumnDiv>
              <RowDiv>{content}</RowDiv>
              <RowDiv> <DateSourcesParagraph>{date}<br/>{sources}</DateSourcesParagraph></RowDiv>
            </ColumnDiv>
         */
        container= (
          <HSDraggableContainer index={this.props.index} key={this.props.id} className={this.getContainerClassName()}>
            <ColumnDiv>
              <RowDiv>{content}</RowDiv>
              <RowDiv> <DateSourcesParagraph>{date}<br/>{sources}</DateSourcesParagraph></RowDiv>
            </ColumnDiv>
          </HSDraggableContainer> )
      } else {
        container= (
          <HSDraggableContainer index={this.props.index} key={this.props.id}  className={this.getContainerClassName()}>
            {infoButton}
            {content}
          </HSDraggableContainer> )
      }
    }
    else {
      // do we need the export mode option with the small screen? currently will have no watermarks
      if (this.props.exportMode) {
        container= (
          <DraggableContainer maxChartItemHeight={this.props.maxChartItemHeight} index={this.props.index} key={this.props.id} className={this.getContainerClassName()}>
            {content}
          </DraggableContainer> )
      } else {
        container= (
          <DraggableContainer maxChartItemHeight={this.props.maxChartItemHeight} index={this.props.index} key={this.props.id} className={this.getContainerClassName()}>
            {infoButton}
            {content}
          </DraggableContainer> )
      }
    }
    return container
  }
}
