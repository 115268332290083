import React from 'react'
import ReactFC from "react-fusioncharts"
import FusionCharts from "fusioncharts"
import charts from "fusioncharts/fusioncharts.charts"
import widgets from "fusioncharts/fusioncharts.widgets"
import fcTheme from "fusioncharts/themes/es/fusioncharts.theme.fusion"
import PowerCharts from "fusioncharts/fusioncharts.powercharts"
import smartTheme from "./smartheme"
import {animationDuration} from "./globalimports/SmartboardConfiguration"
import Errorwraper from "./Errorwraper"

FusionCharts.options.creditLabel = false;
PowerCharts(FusionCharts)
charts(FusionCharts);
widgets(FusionCharts);
charts(FusionCharts);
widgets(FusionCharts);
FusionCharts.addDep(fcTheme)
FusionCharts.addDep(smartTheme)
const ThemeCollection = [fcTheme, smartTheme]
export default class SmartboardChartWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasNull: this.props.hasNull
    }
    this.cwRef = React.createRef()
    this.componentDidMount = this.componentDidMount.bind(this)
    this.getRenderedChartHeight = this.getRenderedChartHeight.bind(this)
    this.getRenderedChartWidth = this.getRenderedChartWidth.bind(this)
  }

  componentDidMount() {
    let pd = this.cwRef.current
    let newState = {parentDom: pd}
    if (pd) {
      newState.dimension = {
        "x": pd.offsetWidth,
        "y": pd.offsetHeight
      }
    }
    this.setState(newState)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let pd = this.state.parentDom
    let newState = {}
    if (pd) {
      if (prevState && (!prevState.dimension || prevState.dimension.x !== pd.offsetWidth || prevState.dimension.y !== pd.offsetHeight)) {
        newState.dimension = {
          "x": pd.offsetWidth,
          "y": pd.offsetHeight
        }
        newState.hasNull = this.props.hasNull
        this.setState(newState)
      }
    }
  }


  /**
   * get the height of the rendered chart
   * */
  getRenderedChartWidth() {
    if (this.state && this.state.dimension) {
      return this.state.dimension.x
    }
  }

  /**
   * get the height of the rendered chart
   * */
  getRenderedChartHeight() {
    if (this.state && this.state.dimension) {
      return this.state.dimension.y
    }
  }

  handleFusionchartData() {
    let fcData = JSON.parse(JSON.stringify(this.props.fcData))
    // console.log(fcData)

    // Skip parameter for native fusion experience
    if(fcData.vanillaFusion) {
      return fcData
    }

    fcData.width = fcData.height = "100%"
    fcData.dataSource.chart.animationDuration = animationDuration
    fcData.dataSource.chart.animation = true
    fcData.dataSource.chart.theme = "smart"
    //props.hideLegend = allow to hide/movethe legend if chart gets to small
    if (fcData.type === "doughnut2d") {
      fcData.dataSource.chart.legendPosition = "bottom"
    }
    if (this.props.hideLegend && fcData && fcData.dataSource && fcData.dataSource.data) {
      if (fcData.type === "doughnut2d") {
        let heightRatio = window.innerHeight / 3 / fcData.dataSource.data.length
        //force legend to be shown below charts ( issues with long labels in legend / scaling issues)
        fcData.dataSource.chart.legendPosition = "bottom"
        //hide the legend if the displayarea gets to small
        if (heightRatio < 41) {
          fcData.dataSource.chart.showLegend = "0"
        }
        fcData.dataSource.chart.legendItemFontSize = "1"
      }
    }
    //resize the center label to avoid ... mostly guestimates
    if (fcData.type === "doughnut2d" && fcData.dataSource.chart.defaultCenterLabel) {
      let themeSize = parseFloat(smartTheme.extension.theme.doughnut2d.chart.centerLabelFontSize)
      let baseValue = themeSize / 900
      let baseSize = window.innerHeight
      //reduce growth when we guess that horizontal size is the limit
      if (this.props.itemSize < 50) {
        let reducedSize = window.innerWidth / 100 * this.props.itemSize
        baseSize = Math.min(reducedSize, baseSize)
      }
      baseValue *= baseSize
      /*
      let additionalShrink = Math.ceil((900 - window.innerHeight)/100)
      while(--additionalShrink > 0) {
        baseValue *= 0.4
      }
       */
      //avoid shrinking to unreadable
      baseValue = Math.max(baseValue, 10);
      //avoid blowing up the number to large
      if (this.props.itemSize) {
        baseValue = Math.max(themeSize, baseValue)
        baseValue = Math.min(themeSize * 2, baseValue)
      }
      fcData.dataSource.chart.centerLabelFontSize = Math.floor(baseValue).toString()
      let baseValueFontSize = (baseValue - 3) / 2 + 10
      if (fcData.dataSource.annotations && fcData.dataSource.annotations.groups) {
        fcData.dataSource.annotations.autoScale = "0"
        fcData.dataSource.annotations.groups.forEach(group => {
          group.items.forEach(item => {
            item.fontSize = baseValueFontSize.toString()
            item.y = `$chartEndY - ${baseValueFontSize}`
          })
        })
        const guessedHeadingHeight = 100
        const availablePieSpace = this.getRenderedChartHeight() - baseValueFontSize - guessedHeadingHeight
        const someAdditionalMargin = 12
        const pieSpaceRadius = (availablePieSpace) / 2
        const computedRadius = Math.floor(pieSpaceRadius - someAdditionalMargin)
        const maxRadius = this.getRenderedChartWidth() / 2 - someAdditionalMargin
        const usedRadius = Math.min(maxRadius, computedRadius)
        fcData.dataSource.chart.pieRadius = usedRadius.toString()
      }
    }
    let customThemeObjects = ThemeCollection.filter((v) => {
      return v.name === fcData.dataSource.chart.theme
    })
    if (customThemeObjects.length === 1 && customThemeObjects[0]) {
      if (fcData.dataSource.annotations && fcData.dataSource.annotations.groups && customThemeObjects[0].CustomAnnotationStyles) {
        fcData.dataSource.annotations.groups.forEach((v, i, a) => {
          v.items.forEach((iv, ii, ia) => {
            Object.keys(customThemeObjects[0].CustomAnnotationStyles).forEach((kv, ki, ka) => {
              if (iv[kv] === undefined) {
                iv[kv] = customThemeObjects[0].CustomAnnotationStyles[kv]
              }
            }, this)
          }, this)
        }, this)
      }
    } else {
      console.error('found more than one theme')
    }
    return fcData
  }

  render() {

    try {
      const fcProps = this.handleFusionchartData()
      return <div ref={this.cwRef}>
      <Errorwraper hasNull={this.state.hasNull} parent={this.cwRef.current} titel={this.props.categorytitel}></Errorwraper>
      <ReactFC {...fcProps}/>
      </div>
    }
    catch(x) {
      return <div ref={this.cwRef}>exception:{x.toString()}</div>

    }
  }

}

