import React from 'react';
import styled from "styled-components";
import {HeaderFontColor, HeaderBackgroundColor, ShadowColor, ManovaLogo, HoverImage} from "./globalimports/SmartboardConfiguration"

const NavIconContainer = styled.span`
  margin: 0px;
  padding-right:2.5vw;
`
const Title = styled.div`
  display:flex;
  padding-left:2.5vw;
  height:100%;
  flex-grow:10;
`
const MainContainer = styled.div`
  font-family: Lato;
  font-weight: 400;
  font-size: 1.6rem;
  background-color: ${HeaderBackgroundColor};
  color: ${HeaderFontColor};
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-content:stretch;
  box-shadow: 0px 4px 11px 2px ${ShadowColor};
  width:100%;
  position: -webkit-sticky; /*safari*/
  position:sticky;
  top:0;
  z-index:5;
  padding: .5rem 0;
  flex-wrap: wrap;
`

const MenuContainer = styled.div`
align-self: flex-end;
`

const BackButton = styled.span`
  min-width:25px;
  margin-right:1rem;
  cursor:pointer;
`


export default class SmartBoardHeaderBar extends React.Component {

  constructor(props) {
    super(props)
    this.state={menuOpen:false}
    this.onToggleMenu=this.onToggleMenu.bind(this)
    this.render = this.render.bind(this)
  }

  onToggleMenu() {
    this.setState({menuOpen:this.state.menuOpen ^ true})
  }

  render() {
    let title = this.props.title || ""
    let imgSrc = process.env.PUBLIC_URL+"/ic/menue.svg"
    if (this.state.menuOpen) {
      imgSrc = process.env.PUBLIC_URL+"/ic/menue-schliessen.svg"
    }
    let menuIcon = <img className={"hoverPointer"} onClick={this.onToggleMenu} alt={"Menü öffnen"} src={imgSrc}/>
    let sidebarButton = (<NavIconContainer style={{float:"right", display: "flex", alignItems: "center", alignContent: "center"}}>{menuIcon}</NavIconContainer>)
    let menuContent = ""
    if (this.props.menuContent && this.state.menuOpen) {
        menuContent=this.props.menuContent
    }
    let backButton = ""
    const smartboardLogo = (<ManovaLogo/>)
    if (this.props.backButtonFunction) {
      let backButtonIcon
      backButtonIcon = <div><HoverImage doHover={process.env.PUBLIC_URL+"/ic/Pfeil-links-gruen.svg"} noHover={process.env.PUBLIC_URL+"/ic/pfeil-links-weiss.svg"}/></div>
      backButton = <BackButton onClick={this.props.backButtonFunction}>{backButtonIcon}</BackButton>
    }
    let firstElement = smartboardLogo
    if (backButton && !this.state.menuOpen) {
      firstElement = (<div style={{display:"flex",alignItems:"center", height: "70px"}}>{backButton}<div >{title}</div></div>)
    }

    let displayname = <div style={{display:"flex", alignSelf: "flex-end", zIndex: -1, widht: "100%", paddingBottom: 18, fontSize: "14px", marginLeft: "auto"}}>{this.props.displayname || 'Österreich'}</div>

    return (
      <MainContainer>
        <Title>
          {firstElement}
          {displayname}
          {sidebarButton}
        </Title>
        <MenuContainer>
          {menuContent}
        </MenuContainer>
      </MainContainer>
    )
  }
}
