import React from "react"
import TextPageHeader from "../components/TextPageHeaderBars"
import TextPage from "../components/pageTemplates/TextPage"
import styled from "styled-components"
import {Button} from "react-bootstrap"
import {ButtonColor} from "../components/globalimports/SmartboardConfiguration"

const AddressLine =
styled.div`
  white-space:nowrap;
`

const TermsParagraph = styled.div`
`

const MainHeader = styled.h1`
  margin-top: 4rem;
  margin-bottom: 0px;
`
const SecondHeader = styled.h2`
  margin-top: 2rem;
  margin-bottom: 0px;
`
const MinorHeader = styled.h3`
  margin-top: 1rem;
  margin-bottom: 0px;
  font-size:90%;
`
export default class Nutzungsbedingungen extends React.Component {
  close() {
    if (this.props.close) {
      this.props.close()
    }
  }
  render() {
    const btnStyle = {"backgroundColor":ButtonColor, borderColor:ButtonColor, marginRight:"1rem"}
    const buttons = (this.props.buttontexts||[]).map(a=> {return <Button key={a.text} style={btnStyle} onClick={a.action}>{a.text}</Button>})
    return (
    <TextPage
      header={<TextPageHeader></TextPageHeader>}
      halfSizeHeader={<TextPageHeader halfsizemode={true}></TextPageHeader>}
    >
      <div style={{padding:"4rem"}}>
        {buttons}
        <MainHeader>Nutzungsbedingungen SMARTBOARD Seilbahnen</MainHeader>
        <TermsParagraph>
          Als Benutzer von Smartboard Seilbahnen stimme ich hiermit ausdrücklich zu
        </TermsParagraph>
        <ol>
          <li> für das von mir vertretene Unternehmen die geforderten Daten in WEBMARK Seilbahnen regelmäßig und rechtzeitig einzugeben; ich nehme zur Kenntnis, dass die Nichteingabe der Daten die Sperre des Smartboardzuganges bewirkt.</li>
          <li>dass die im Smartboard Seilbahnen verfügbaren aktuellsten Branchendaten nicht bzw. nur nach Rücksprache mit dem FV veröffentlicht oder an Medien weitergeben werden dürfen.</li>
          <li>dass die im Smartboard Seilbahnen verfügbaren Daten und Informationen nach bestem Wissen und Gewissen recherchiert und verarbeitet werden, aber jegliche Haftungen dafür ausgeschlossen sind.</li>
          <li>dass die mir übermittelten Zugangsdaten zum Smartboard Seilbahnen einen höchstpersönlichen Zugang begründen und von mir nicht an Dritte weitergeben werden dürfen.</li>
          <li>dass aus der Benutzung von Smartboard Seilbahnen keine wie immer gearteten Ansprüche hinsichtlich Funktionalität, Inhalt, Umfang und Zeitpunkt der Informationsbereitstellung erwachsen.</li>
        </ol>

        <MainHeader>
          Datenschutzerklärung SMARTBOARD Seilbahnen
        </MainHeader>
        <SecondHeader>
          Rechtsgrundlage
        </SecondHeader>
        <TermsParagraph>
          Die EU-Datenschutz-Grundverordnung, das Datenschutzgesetz 2000 sowie das Datenschutz- Anpassungsgesetz 2018 dienen dem Recht auf Schutz personenbezogener Daten. Wir verarbeiten Ihre Daten ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, DSG 2018, TKG 2003).
        </TermsParagraph>

        <SecondHeader>
          Name der Datenverarbeitung SMARTBOARD Seilbahnen
        </SecondHeader>
        <MinorHeader>
          RECHTSGRUNDLAGE EINWILLIGUNG
        </MinorHeader>
        <TermsParagraph>
          Sie haben uns Daten über sich freiwillig zur Verfügung gestellt und wir verarbeiten diese Daten auf Grundlage Ihrer Einwilligung zu folgenden Zwecken: Zugang zum Smartboard, Informationen über Datenaktualisierungen, Information über unsere Produkte;
        </TermsParagraph>
        <TermsParagraph>
          Sie können diese Einwilligung jederzeit widerrufen. Ein Widerruf hat zur Folge, dass wir Ihre Daten ab diesem Zeitpunkt zu oben genannten Zwecken nicht mehr verarbeiten. Für einen Widerruf wenden Sie sich bitte an: <a href={"mailto:seilbahnen@wko.at?subject=Smartboard"}>seilbahnen@wko.at</a>, Betreff: Smartboard
        </TermsParagraph>

        <MinorHeader>
          RECHTSGRUNDLAGE VERTRAGSERFÜLLUNG
        </MinorHeader>
        <TermsParagraph>
          Die von Ihnen bereit gestellten Daten sind zur Vertragserfüllung bzw. zur Durchführung vorvertraglicher Maßnahmen erforderlich. Ohne diese Daten können wir den Vertrag mit Ihnen nicht abschließen. Die Daten werden benötigt, um einen persönlichen Zugang zu gewährleisten. Darüber hinaus, um das Passwort für den persönlichen Zugang direkt im übermittelten Link speichern zu können und ein schnelles Einsteigen ohne Eingabe des Passwortes sicherzustellen.
        </TermsParagraph>

        <MinorHeader>
          SPEICHERDAUER/LÖSCHUNGSFRIST
        </MinorHeader>
        <TermsParagraph>
          Wir speichern Ihre Daten, solange Sie Zugang zum Smartboard haben.
        </TermsParagraph>

        <MinorHeader>
          AUFTRAGSVERARBEITER
        </MinorHeader>
        <TermsParagraph>
          Für diese Datenverarbeitung ziehen wir die MANOVA GmbH heran.<br />
          <b>Kontakt:</b>
          <AddressLine>
            MANOVA GmbH
          </AddressLine>
          <AddressLine>
            Wipplingerstraße 23/23
          </AddressLine>
          <AddressLine>
            1010 Wien
          </AddressLine>
        </TermsParagraph>
        <TermsParagraph>
          <b>Sie erreichen uns unter folgenden Kontaktdaten:</b>
          <AddressLine>Fachverband der Seilbahnen</AddressLine>
          <AddressLine>Wiedner Hauptstraße 63</AddressLine>
          <AddressLine>1045 Wien</AddressLine>
          <AddressLine>T: <a href={"tel:+435909003166"}>+43 5 90 900-3166</a></AddressLine>
          <AddressLine>E: <a href={"mailto:seilbahnen@wko.at"}>seilbahnen@wko.at</a></AddressLine>
          <AddressLine>W: <a href={"https://www.seilbahnen.at"}>https://www.seilbahnen.at</a></AddressLine>


        </TermsParagraph>
        <TermsParagraph>
          <b>
            Unseren Datenschutzbeauftragten erreichen Sie unter:
          </b>
          <a href={"mailto:dsb@wko.at"}>dsb@wko.at</a>
        </TermsParagraph>

        <TermsParagraph>
          <MinorHeader>RECHTSBEHELFSBELEHRUNG</MinorHeader>
          <TermsParagraph>
            Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit und Widerspruch zu. Dafür wenden Sie sich an uns.
          </TermsParagraph>
          <TermsParagraph>
            Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist die <a href={"https://dsb.gv.at"}>Datenschutzbehörde</a> zuständig.
          </TermsParagraph>
        </TermsParagraph>


        <SecondHeader>
          Kontaktadressen
        </SecondHeader>
        <MinorHeader>
          AUFSICHTSBEHÖRDE FÜR DIE EINHALTUNG DATENSCHUTZRECHTLICHER BESTIMMUNGEN IN ÖSTERREICH:
        </MinorHeader>
        <AddressLine>Österreichische Datenschutzbehörde </AddressLine>
        <AddressLine>Barichgasse 40-42 </AddressLine>
        <AddressLine>1030 Wien </AddressLine>
        <AddressLine>T:<a href={"tel:+431521520"}>+43 1 52 152-0</a></AddressLine>
        <AddressLine>E: <a href={"dsb@dsb.gv.at"}>dsb@dsb.gv.at</a></AddressLine>
        <AddressLine>W: <a href={"https://www.dsb.gv.at/"}>https://www.dsb.gv.at/</a> </AddressLine>

        <MinorHeader>
          KONTAKTADRESSE RUND UM DAS THEMA DATENSCHUTZRECHT:
        </MinorHeader>
      <AddressLine>Wirtschaftskammer Wien</AddressLine>
      <AddressLine>Service Center</AddressLine>
      <AddressLine>Straße der Wiener Wirtschaft 1</AddressLine>
      <AddressLine>1020 Wien</AddressLine>
      <AddressLine>T: <a href={"tel:+431514501010"}>+43 1 514 50 1010</a></AddressLine>
      <AddressLine>Kontaktformular: <a href={"https://www.wko.at/service/w/kontakt-wirtschaftsrecht-gewerberecht.html"}>https://www.wko.at/service/w/kontakt-wirtschaftsrecht-gewerberecht.html</a></AddressLine>

        <SecondHeader>
          Hinweise
        </SecondHeader>
        <TermsParagraph>
          Bei allen personenbezogenen Bezeichnungen gilt die gewählte Form für alle Geschlechter.
          <br />
          Datum 28.12.2021
        </TermsParagraph>
        {buttons}
      </div>
    </TextPage>)

  }
}
