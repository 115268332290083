import {exampleChartData, exampleHtmlData} from "./exData"


const replaceEmptyWithFallback = (data) => {
  const title = getTitle(data)
  let output;
  if (isEmptyData(data)) {
    const fallback = lookupFallbackChart(title, data)
    output = {
      "titlechartdata": fallback,
      "hasNull": true
    }
  } else {
    output = {
      "titlechartdata": data,
      "hasNull": false
    }
  }
  return output
}

const extractTitleFromHTMLText = (t) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(t, "text/html")
  const titles = doc.getElementsByClassName("html_chart__title")
  const textTitle = titles[0].innerHTML
  return textTitle.toString()
}
const lookupFallbackChart = (name, data) => {
  let htmlChart = exampleHtmlData.find(e => extractTitleFromHTMLText(e) === name)
  let fusionChart = [...exampleChartData].find(e => e.chart.caption === name)
  if (htmlChart) {
    return {"__html": htmlChart}
  } else {
    if (fusionChart) {
      let chartType
      if (fusionChart.chartType) {
        chartType = fusionChart.chartType
      }
      else {
        chartType = data.type
      }
      return {
        "dataSource": fusionChart,
        dataFormat:"json",
        "type": chartType
      }
    }
    else {
      return {"__html": name}
    }
  }
}

const getTitle = (data) => {
  if (data["__html"]) {
    return extractTitleFromHTMLText(data["__html"])
  } else {
    return data.dataSource.chart.caption
  }
}
const isEmptyData = (data) => {
  let isEmpty
  if (data["__html"]) {
    const html = data["__html"]
    isEmpty = html.includes("null") || html.includes("NaN") || html.includes("Zu wenig Originalwerte")
  } else {
    const numberOfEmpty = data?.dataSource?.data?.filter(d => d.value === null || d.value === undefined)?.length
    isEmpty = 0 < numberOfEmpty
  }
  return isEmpty
}


export default replaceEmptyWithFallback
