import SmartboardList from "./SmartboardList";
import AuthenticationForm from './AuthenticationForm'
import React from "react";
import Nutzungsbedingungen from "../pages/Nutzungsbedingungen"

const stateDisplayTerms = {"displayTerms":true}
const stateNoDisplayTerms = {"displayTerms":false}
export default
class Sboard extends React.Component{

  constructor(props) {
    super(props)
    this.state = {
      listItems:false,
      combinedCategories:[]
    }
    //will hold the categoryid for the extended category
    this.state.extendedcategory = "";
    this.listReady = this.listReady.bind(this)
    this.clickCategory = this.clickCategory.bind(this)
    this.displayTerms = this.displayTerms.bind(this)
    this.hideTerms = this.hideTerms.bind(this)
  }
  displayTerms() {
    this.setState(stateDisplayTerms)
  }
  hideTerms() {
    this.setState(stateNoDisplayTerms)
  }

  render() {
    if (this.state.combinedCategories.length) {
      let className = "export_mode"
      if (!this.state.showTitle) {
        className += " no_show_title"
      }
      if (this.state.displayTerms) {
        return <Nutzungsbedingungen buttontexts={[{action: this.hideTerms, text: "Nutzungsbedingungen schließen"}]}/>
      }
      else {
        return (
          <SmartboardList
            listItems={this.state.listItems}
            extended={this.state.extendedcategory}
            boardId={this.state.boardId || 'AAA'}
            listCategories={this.state.listCategories}
            combinedCategories={this.state.combinedCategories}
            updateCategory={this.updateCategory}
            exportMode={this.state.exportMode}
            showTitle={this.state.showTitle}
            className={className}
            sources={this.state.sources}
            displayname={this.state.displayname}
            displayTerms={this.displayTerms}
          >
          </SmartboardList>
        )
      }
    }
    else {
      return (<AuthenticationForm handleAuthentication={this.listReady}/>)
    }
  }

  listReady(listItems, categoryList, boardId, exportMode=false, showTitle=false, sources, displayname="") {
    //create an list per category
    //set all lists to not extended
    //setup combined categories with list
    let combinedCategories
    combinedCategories = categoryList.map((v,i,a)=>{
      let currentCat = {...v}
      currentCat.list = listItems.filter((fv, fi, fa)=>{
        return fv.categoryid === v.categoryid
      })
      return currentCat
    })
    this.setState({combinedCategories:combinedCategories, boardId:boardId, exportMode:exportMode, showTitle:showTitle, sources:sources, displayname:displayname})
  }

  updateCategory(categoryId, newList) {
    let combinedCategories = Array.from(this.state.combinedCategories).map((v,i,a)=>{
      return {...v}
    })
    let catToModify = combinedCategories.find((a)=>a.categoryid = categoryId)
    if (catToModify) {
      catToModify.list = newList
    }
    this.setState({combinedCategories:combinedCategories})
  }

  clickCategory(categoryId) {
    this.setState({"extendedcategory":categoryId})
  }

}
