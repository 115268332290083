import React from 'react';
import styled from "styled-components";
import {Headline, FooterFontColor, HeaderBackgroundColor} from "./globalimports/SmartboardConfiguration"

const FlexColumn = styled.div`
  display:flex;
  flex-direction:column;
  textAlign: left;
`
const FlexRow = styled.div`
  display:flex;
  flex-direction:row;
  text-align: left;
  min-width: 25vw;
  padding: 10px 0;
  margin-left: 2.5vw;
  margin-right: 2.5vw;
`


const LinkContainer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:flex-end;
  align-items:flex-end;
  text-transform:uppercase;
`
const CopyRight = styled.div`
  text-align:left;
`
const MainContainer = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  width:100%;
  box-shadow: ${(props) => {
  if (props.shadow) {
    return "0px -4px 11px 2px lightgrey";
  } else {
    return "";
  }
}};
  background-color:${HeaderBackgroundColor};
  color:${FooterFontColor};
  ${(props) => {
  return props.margin || ""
}}
  /*
  margin-top:3rem;
  */
  min-height:7vh;
`


export default class SmartBoardFooter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.render = this.render.bind(this)
  }

  // <FlexColumn style={{flexGrow: 1, maxWidth: '370px', padding: '10px'}}>
  render() {
    let smallCol = {flexGrow: 1, maxWidth: '370px', minWidth: '200px'}
    let copyrighttext = "\u00a9 " + (new Date()).getFullYear() + " MANOVA GmbH"
    return (
      <MainContainer shadow={this.props.shadow} margin={this.props.margin}>
        <FlexRow style={{alignItems: 'stretch', alignContent: 'stretch', flexWrap: 'wrap'}}>
          <FlexColumn style={{...smallCol}}>
            <Headline>Kontakt</Headline>
            <div>
              Treten Sie mit uns in Kontakt. Schreiben Sie uns
            </div>
            <div>
              eine Nachricht, oder rufen Sie einfach an!
            </div>
            <div>{'\u00a0'}</div>
            <div>{'\u00a0'}</div>
            <div>+43 1 710 75 35 - 0</div>
            <div><a className={"footerLink"} href={"mailto:seilbahnen@manova.at"}>seilbahnen@manova.at</a></div>
          </FlexColumn>
          <FlexColumn style={{...smallCol}}>
            <Headline>{'\u00a0'}</Headline>
            <div>MANOVA GmbH</div>
            <div>Wipplingerstraße 23/23</div>
            <div>1010 Wien | Austria</div>
            <div>{'\u00a0'}</div>
            <div>{'\u00a0'}</div>
            <div>
              <CopyRight>{copyrighttext}</CopyRight>
            </div>
          </FlexColumn>
          <FlexColumn style={{alignSelf: "flex-end", flexGrow: 10, alignContent: "flex-end", minWidth: '55vw'}}>
            <LinkContainer className={"bottom"}>
            <span style={{marginRight: "2em"}}>
              <a className={"footerLink"} href={"https://webmark.manova.at/static/datenschutz/Datenschutzerkl%C3%A4rung%20WEBMARK.pdf"}>Datenschutz</a>
            </span>
              <span>
              <a className={"footerLink"} href={"https://www.manova.at/Impressum"}>Impressum</a>
            </span>
            </LinkContainer>
          </FlexColumn>
        </FlexRow>
      </MainContainer>
    );
  }
}
