import React from "react";
import styled from "styled-components";
import CategoryPageColumn from './CategoryPageColumn'
import SmartboardHeaderBar from "./SmartboardHeaderBar"
import SmartboardFooterBar from "./SmartboardFooterBar"
import MenuContent from "./MenuContent"
const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export default
class CategoryPage extends React.Component {
  constructor(props) {
    super(props)
    this.backFunction = () => {window.scrollTo(0,0);props.clickFeedback()}
    let Items = this.props.listItems;
    this.state = ({})
    this.state.listItems = Items;
    this.state.category=props.category;
  }

  render() {
    const chartHeight = Math.max(Math.floor(0.3*window.innerHeight), 250)
    let menuContent=(<MenuContent category={this.props.category} showSources={false} sources={this.props.sources} displayTerms={this.props.displayTerms}/>)
    return (<div>
        <SmartboardHeaderBar icon={this.props.category.icon} menuContent={menuContent} backButtonFunction={this.backFunction} title={this.props.category.title} displayname={this.props.displayname}/>
        <FlexContainer>
          <CategoryPageColumn
            listItems={this.state.category.list}
            categoryId={this.state.category.categoryid}
            boardId={this.props.boardId}
            vertimode={true}
            height={chartHeight}
            exportMode={this.props.exportMode}
            showTitle={this.props.showTitle}
          />
        </FlexContainer>
        <SmartboardFooterBar margin={"margin-top:3rem;"}/>
      </div>
    )
  }

}
