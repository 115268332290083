export const exampleChartData = [{
  "data": [{
    "label": "Winter 2016/17",
    "value": 52058566,
    "displayValue": ""
  }, {
    "label": "Winter 2017/18",
    "value": 55042378,
    "displayValue": " | 5,7%"
  }, {
    "label": "Winter 2018/19",
    "value": 54352796,
    "displayValue": " | -1,3%"
  }, {
    "label": "Winter 2019/20",
    "value": 47114828,
    "displayValue": " | -13,3%"
  }, {
    "label": "Winter 2020/21",
    "value": 9253038,
    "displayValue": " | -80,4%"
  }],
  "chart": {
    "palettecolors": "#373a47",
    "caption": "Entwicklung Ersteintritte",
    "xAxisName": "",
    "yAxisName": "",
    "subCaption": "",
    "plotToolText": "$label | $dataValue $displayValue",
    "setAdaptiveYMin": "1",
    "canvasTopPadding": "10",
    "animationDuration": "2",
    "animation": true,
    "theme": "smart"
  },
  "trendlines": [{
    "line": [{
      "color": "#131024",
      "endvalue": "",
      "thickness": "2",
      "startvalue": 43564321
    }]
  }],
  "chartType": "line"
}, {
  "data": [{
    "color": "#F5F5F3",
    "label": "",
    "value": 40.20618556701031
  }, {
    "color": "#373a47",
    "label": "",
    "value": 59.79381443298969
  }],
  "chart": {
    "palettecolors": "#373a47",
    "caption": "Alpine Wintersportler",
    "subcaption": "Österreich",
    "defaultCenterLabel": "60%",
    "animationDuration": "2",
    "animation": true,
    "theme": "smart",
    "legendPosition": "bottom",
    "centerLabelFontSize": "12",
    "pieRadius": "47"
  },
  "annotations": {
    "groups": [{
      "items": [{
        "x": "$chartCenterX + 0",
        "y": "$chartEndY - 14.766666666666667",
        "text": "2,9 Mio.",
        "type": "text",
        "color": "#000000",
        "fontSize": "14.766666666666667"
      }]
    }],
    "autoscale": "1",
    "scaleText": "1",
    "autoScale": "0"
  },
  "chartType": "doughnut2d"
}, {
  "data": [{
    "label": "Skipisten",
    "value": 57.39776463112074
  }, {
    "label": "Größe des Skigebiets",
    "value": 52.59722089204297
  }, {
    "label": "Erreichbarkeit",
    "value": 51.04038888236314
  }, {
    "label": "Schnee-/Pistenverhältnisse",
    "value": 49.012632230602286
  }, {
    "label": "Schneesicherheit auf den Pisten",
    "value": 38.991482982526115
  }],
  "chart": {
    "palettecolors": "#373a47",
    "caption": "Top 5 Kaufentscheidungsgründe",
    "xAxisName": "",
    "yAxisName": "",
    "subCaption": "",
    "numberSuffix": "%",
    "animationDuration": "2",
    "animation": true,
    "theme": "smart"
  },
  "chartType": "bar2d"
}, {
  "data": [{
    "label": "Winter 2017/18",
    "value": 25.662847480827537
  }, {
    "label": "Winter 2018/19",
    "value": 25.246428993588292
  }, {
    "label": "Winter 2019/20",
    "value": 26.025298513996976
  }, {
    "label": "Winter 2020/21",
    "value": 25.604714353789326
  }],
  "chart": {
    "palettecolors": "#373a47",
    "caption": "Anteil Kinder- und Jugendkarten",
    "xAxisName": "",
    "yAxisName": "",
    "subCaption": "",
    "numberSuffix": "%",
    "animationDuration": "2",
    "animation": true,
    "theme": "smart"
  },
  "chartType": "line"
}, {
  "data": [{
    "color": "#F5F5F3",
    "label": "",
    "value": 70.48493793654836
  }, {
    "color": "#373a47",
    "label": "",
    "value": 29.51506206345164
  }],
  "chart": {
    "palettecolors": "#373a47",
    "caption": "Durchschnittspreis",
    "subcaption": "in % des Höchstpreises",
    "defaultCenterLabel": "30%",
    "animationDuration": "2",
    "animation": true,
    "theme": "smart",
    "legendPosition": "bottom",
    "legendItemFontSize": "1",
    "centerLabelFontSize": "12"
  },
  "chartType": "doughnut2d"
}, {
  "data": [{
    "label": "2011",
    "value": 503
  }, {
    "label": "2012",
    "value": 492
  }, {
    "label": "2013",
    "value": 477
  }, {
    "label": "2014",
    "value": 539
  }, {
    "label": "2015",
    "value": 572
  }, {
    "label": "2016",
    "value": 594
  }, {
    "label": "2017",
    "value": 538
  }, {
    "label": "2018",
    "value": 657
  }, {
    "label": "2019",
    "value": 708
  }, {
    "label": "2020",
    "value": 312
  }, {
    "label": "2021",
    "value": 384
  }],
  "chart": {
    "palettecolors": "#373a47",
    "caption": "Brancheninvestitionen in Mio.",
    "xAxisName": "",
    "yAxisName": "",
    "subCaption": "",
    "setAdaptiveYMin": "1",
    "canvasTopPadding": "10",
    "animationDuration": "2",
    "animation": true,
    "theme": "smart"
  },
  "chartType": "column2d"
}, {
  "chart": {
    "palettecolors": "#373a47",
    "caption": "Trends",
    "showValues": 0,
    "yAxisMaxValue": 100,
    "yAxisMinValue": 0,
    "animationDuration": "2",
    "animation": true,
    "theme": "smart"
  },
  "dataset": [{
    "data": [{"value": 17}, {"value": 43}, {"value": 37}, {"value": 19}, {"value": 21}, {"value": 14}, {"value": 28}, {"value": 30}, {"value": 56}, {"value": 71}, {"value": 47}, {"value": 28}, {"value": 35}, {"value": 38}, {"value": 62}, {"value": 78}, {"value": 67}, {"value": 38}, {"value": 33}, {"value": 37}, {"value": 36}, {"value": 46}, {"value": 82}, {"value": 82}, {"value": 41}, {"value": 53}, {"value": 35}, {"value": 38}],
    "seriesname": "Ski + Schi + Wintersport + Snowboard"
  }, {
    "data": [{"value": 10}, {"value": 15}, {"value": 17}, {"value": 11}, {"value": 10}, {"value": 8}, {"value": 7}, {"value": 10}, {"value": 7}, {"value": 4}, {"value": 8}, {"value": 10}, {"value": 6}, {"value": 0}, {"value": 9}, {"value": 10}, {"value": 20}, {"value": 3}, {"value": 2}, {"value": 9}, {"value": 7}, {"value": 14}, {"value": 6}, {"value": 8}, {"value": 2}, {"value": 2}, {"value": 6}, {"value": 1}],
    "seriesname": "Wandern + Mountainbike + Bergsommer + Sommerrodeln"
  }],
  "categories": [{
    "category": [{
      "label": "",
      "tooltext": "19.11.21"
    }, {
      "label": "20.11.21",
      "tooltext": "20.11.21"
    }, {
      "label": "",
      "tooltext": "21.11.21"
    }, {
      "label": "",
      "tooltext": "22.11.21"
    }, {
      "label": "",
      "tooltext": "23.11.21"
    }, {
      "label": "24.11.21",
      "tooltext": "24.11.21"
    }, {
      "label": "",
      "tooltext": "25.11.21"
    }, {
      "label": "",
      "tooltext": "26.11.21"
    }, {
      "label": "",
      "tooltext": "27.11.21"
    }, {
      "label": "28.11.21",
      "tooltext": "28.11.21"
    }, {
      "label": "",
      "tooltext": "29.11.21"
    }, {
      "label": "",
      "tooltext": "30.11.21"
    }, {
      "label": "",
      "tooltext": "1.12.21"
    }, {
      "label": "2.12.21",
      "tooltext": "2.12.21"
    }, {
      "label": "",
      "tooltext": "3.12.21"
    }, {
      "label": "",
      "tooltext": "4.12.21"
    }, {
      "label": "",
      "tooltext": "5.12.21"
    }, {
      "label": "6.12.21",
      "tooltext": "6.12.21"
    }, {
      "label": "",
      "tooltext": "7.12.21"
    }, {
      "label": "",
      "tooltext": "8.12.21"
    }, {
      "label": "",
      "tooltext": "9.12.21"
    }, {
      "label": "10.12.21",
      "tooltext": "10.12.21"
    }, {
      "label": "",
      "tooltext": "11.12.21"
    }, {
      "label": "",
      "tooltext": "12.12.21"
    }, {
      "label": "",
      "tooltext": "13.12.21"
    }, {
      "label": "14.12.21",
      "tooltext": "14.12.21"
    }, {
      "label": "",
      "tooltext": "15.12.21"
    }, {
      "label": "",
      "tooltext": "16.12.21"
    }]
  }],
  "chartType": "msline"
}, {
  "data": [{
    "label": "Sommer 2019",
    "value": 17.5,
    "displayValue": " "
  }, {
    "label": "Sommer 2020",
    "value": 18.7,
    "displayValue": " | 6,9%"
  }, {
    "label": "Sommer 2021",
    "value": 17.7,
    "displayValue": " | -5,3%"
  }],
  "chart": {
    "palettecolors": "#373a47",
    "caption": "Entwicklung Ersteintritte",
    "xAxisName": "",
    "yAxisName": "",
    "subCaption": "",
    "numbersuffix": " Mio.",
    "plotToolText": "$label | $dataValue $displayValue",
    "setAdaptiveYMin": "1",
    "canvasTopPadding": "10",
    "animationDuration": "2",
    "animation": true,
    "theme": "smart"
  },
  "trendlines": [{
    "line": [{
      "color": "#131024",
      "endvalue": "",
      "thickness": "2",
      "startvalue": 17.966666666666665
    }]
  }],
  "chartType": "column2d"
}]

export const exampleHtmlData = ["<div class=\"html_chart\"> <div class=\"html_chart__header\"> <div class=\"html_chart__title\">Wertschöpfungs-Multiplikator bei Seilbahnen (netto)</div> </div> <div class=\"html_chart__body\"> <div class=\"html_largevalue\"> <div class=\"html_largevalue__value\">8,3</div><span class=\"html_largevalue__text sm\">Aus 611,9 Mio. € direkter Netto-Wertschöpfung werden 5,1 Mrd. € gesamte Wertschöpfung. </span> </div></div></div>", "<div class=\"html_chart\"><div class=\"html_chart__title med\"><i class=\"far fa-handshake\"></i></div><div class=\"html_chart__body\"><div class=\"html_chart__title\" style=\"margin-bottom:.5em\">„Seilbahnen sichern alpinen Lebensraum“</div><div class=\"html_largevalue\" style=\"margin-bottom:2em\"><div class=\"html_largevalue__value med\">79%</div><div class=\"d-bars__bar\"><div class=\"d-bars__valuebar_grey\" style=\"width:79%\"></div></div></div></div></div>"]
