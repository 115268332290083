import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {library} from "@fortawesome/fontawesome-svg-core"
import {fal} from "@fortawesome/pro-light-svg-icons"
import {far} from "@fortawesome/pro-regular-svg-icons"
import {fad} from "@fortawesome/pro-duotone-svg-icons"
import {fas} from "@fortawesome/pro-solid-svg-icons"
import React from "react"
import styled from "styled-components";

library.add(fal)
library.add(far)
library.add(fad)
library.add(fas)
export {FontAwesomeIcon,library}
const MainColor1="rgba(19, 16, 36, 1)"
const MainColor1_Transparent="rgba(19, 16, 36, .2)"
const MainColor2="rgba(245, 245, 243, 1)"
const MainColor3="rgba(0, 123, 69, 1)"
const MainColor4="rgba(255, 255, 255, 1)"
const MainColor5="rgba(20, 28, 75, 1)"
const BackgroundColor = "rgb(245, 245,245)"
export const PageBackgroundColor = BackgroundColor
export const animationDuration = "2"
export const chartOuterMargin = 2
export const minWidthForSingleLayout = 720
export const dragDelayInMs = 125
export const MainBackgroundColor=MainColor2
export const MainFontColor=MainColor5
export const HeaderFontColor = MainColor4
export const HeaderBackgroundColor = MainColor1
export const ShadowColor = MainColor1_Transparent
export const FooterFontColor = MainColor2
export const LinkHoverColor = MainColor3
export const ButtonColor = MainColor3
export const dragBackgroundColor = MainColor2
export const categoryIconColor=MainColor3
export const nbspCharacter = "\u00a0"

export const CenteredContent = styled.div`
  display:flex;
  align-items:center;
`
const LogoText = styled.span`
  margin-left:10px;
  font-size:x-large;
`
export const Headline = styled.h1`
  font-family:InriaSerif;
  font-weight:1000;
  margin-top:0px;
`
class WebmarkLogo extends React.Component {
  render() {
    return (<img alt={"Webmark"} src={process.env.PUBLIC_URL+"/ic/webmark-neg-quatrat-1.svg"} style={{height:"65px"}}></img>)
  }
}

class ManovaLogo extends React.Component {
  render() {
    return (<img width={"150px"} height={"70px"} alt={"MANOVA"} src={process.env.PUBLIC_URL+"/ic/manova-neg-quer-ohnerand.svg"}></img>)
  }
}
/*class WebmarkMobile extends React.Component {
  render() {
    return (
        <div>
          <h1>webmar</h1><img alt={"Webmark"} src={process.env.PUBLIC_URL+"/ic/webmark-symbol-gruen.svg"}></img>
        </div>
    );
  }
}*/

class SmartboardLogo extends React.Component {
  render() {
    return (
      <img alt={""} src={process.env.PUBLIC_URL+"/ic/smartboard-icon-gross.svg"}></img>
    )
  }
}
class SmartboardLogoWithText extends React.Component {
  render() {
    return (
      <CenteredContent {...this.props}>
        <SmartboardLogo></SmartboardLogo>
        <LogoText>
          SMARTBOARD
        </LogoText>
      </CenteredContent>
    )
  }
}
/**
 * onCLick -> fn to be called
 * style for the div
 * noHover -> url for default image
 * doHover -> url for hovered image
 * */
class HoverImage extends React.Component {
  constructor(props) {
    super(props)
    this.handleMouseHover = this.handleMouseHover.bind(this)
    this.state = {
      hovered: false,
    }
  }

  handleMouseHover() {
    this.setState(this.toggleHoverState);
  }
  toggleHoverState(state) {
    return {
      hovered: !state.hovered
    };
  }
  render() {
    /*
    noHover =  process.env.PUBLIC_URL+"/ic/pfeil-rechts-weiter.svg"
    doHover =  process.env.PUBLIC_URL+"/ic/pfeil-rechts-gruen.svg"
    */
    const noHover =  this.props.noHover
    const doHover = this.props.doHover
    let showArrowUrl = noHover
    if (this.state.hovered || this.props.hovered) {
      showArrowUrl = doHover
    }
    const showArrow = ( <img alt={this.props.alt||""} src={showArrowUrl}/> )
    return (<span onClick={this.props.onClick} style={this.props.style}
      onMouseEnter={this.handleMouseHover}
      onMouseLeave={this.handleMouseHover}
    >{showArrow}</span>)
  }
}
/*
*
* # FARBSCHEMA
## Primärfarbe 1 (dunkelblau):
    HEX: #131024
    RGB: (19, 16, 36)
## Primärfarbe 2 (hellgrau):
    HEX: #F5F5F3
    RGB: (245, 245, 243)
## Primärfarbe 3 (manovagrün):
    HEX: #007B45
    RGB: (0, 123, 69)
## Primärfarbe 4 (weiss):
    HEX: #FFFFFF
    RGB: (255, 255, 255)
## Primärfarbe 5 (blau):
    HEX: #141C4B
    RGB: (20, 28, 75)

*
* **/
export {ManovaLogo, WebmarkLogo, SmartboardLogo, SmartboardLogoWithText, HoverImage}

