import React from 'react'

const Errorwraper = props => {

  //let height = props.parent !== null ? props.parent.offsetWidth : "100%"
  //let width = props.parent !== null ? props.parent.offsetHeight : "100%"

    const styles1 = {
      position: "absolute",
      width: props.parent === undefined ? "100%" : props.parent !== null ? props.parent.offsetWidth : "100%",
      height: props.parent === undefined ? "100%" : props.parent !== null ? props.parent.offsetHeight : "100%",
      zIndex: 2,
      //opacity: 0.1,
      backgroundColor: "transparent",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }

    const styles2 = {
      position: "absolute",
      width: props.parent === undefined ? "100%" : props.parent !== null && props.parent.offsetWidth,
      height: props.parent === undefined ? "100%" : props.parent !== null && props.parent.offsetHeight,
      zIndex: 2,
      opacity: 0.7,
      backgroundColor: "white"
    }

     if (props.parent !== null && props.hasNull) {

      return (
        <div style = {styles1}>
        <div style = {styles2}></div>
        </div>
     ) }


     else return null
  }

  export default Errorwraper

  // <div style = {styles1}>
  // <div className='errorWrapper_title'>Zur Kategorie {props.titel}</div>
  // </div>
