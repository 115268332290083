import React from "react";
import styled from "styled-components";
import CategoryPageItem from "./CategoryPageItem";
import { sortableContainer } from "react-sortable-hoc"
import arrayMove from "array-move"
import store from "store"
import {
  minWidthForSingleLayout as minWidth,
  dragDelayInMs as dragDelay,
} from "./globalimports/SmartboardConfiguration";

const Container = styled.div`
  min-height:100vh;
  height: 100%;
  width: 100%;
  margin-left: 0vw;
  margin-right: 0vw;
  ${(props)=>{
    if (props.halfsizemode) {
      return "padding-left: 2vw; padding-right: 2vw;"
    }
    else {
      return "padding-left: 0vw; padding-right: 0vw;"
    }
    
  }}
  border: 0px solid green;
  flex-grow:1;
  display:flex;
  flex-wrap:wrap;
  justify-content:center;
  align-content:center;
  align-items:stretch;
  /*
  align-content:flex-start;
  */
  width:${(props) => {
  return (props.width || "100%")
}};
`;

const SortableList = sortableContainer(({ children, halfsizemode }) => {
  return <Container halfsizemode={halfsizemode}>{children}</Container>
})

export default class CategoryPageColumn extends React.Component {

  constructor(props) {
    super(props)
    let orderItemIds = this.getItemOrder().reverse()
    let unorderedItems = Array.from(this.props.listItems)
    let unorderedItemIds = unorderedItems.map(a => a.id)
    let allItemIds = unorderedItemIds.sort((a,b)=> {
      let pos1 = orderItemIds.indexOf(a)
      let pos2 = orderItemIds.indexOf(b)
      //keep order if both elements are new elements
      if (pos1 === -1 && pos2 === -1) {
        pos1 = unorderedItemIds.indexOf(a)
        pos2 = unorderedItemIds.indexOf(b)
        //console.log(pos1, pos2, "POS1, POS2 after if")
      }
      //only the first element is unknown order it behind the second
      if (pos1 === -1) {
        pos1 = 1 + pos2
      }
      if (pos2 === -1) {
        pos2 = 1 + pos1
      }
      return pos2-pos1;
    }).reverse()
    let sortedItems = allItemIds.map((v,i,a) => {
      return unorderedItems.find((fv,fi,fa)=> {
          return fv.id===v
        }
      )
    })
    this.state = {
      "listItems": sortedItems
    }
    this.onSortEnd = this.onSortEnd.bind(this)
    this.state.maxChartItemHeight = window.innerHeight * 0.8
    this.state.halfsizemode = (window.innerWidth > (minWidth))
    this.onResize = this.onResize.bind(this)
    window.addEventListener("resize", this.onResize)
  }

  getOrderStorageName() {
    return this.props.boardId + "-order-" + this.props.categoryId
  }

  getItemOrder () {
    return JSON.parse(store.get(this.getOrderStorageName()) || "[]")
  }

  setItemOrder (Items) {
    let sortedIds = Items.map((v,i,a)=>{
      return v.id
    }).reverse()
    store.set(this.getOrderStorageName(),JSON.stringify(sortedIds))
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize)
  }

  onResize (e) {
    let newState = {}
    newState.halfsizemode = (window.innerWidth > (minWidth))
    newState.maxChartItemHeight = window.innerHeight * 0.8
    this.setState(newState)
  }

  onSortEnd ({oldIndex, newIndex}) {
    let Items = Array.from(this.state.listItems)
    let newState = {
      ...this.state,
      listItems:
        arrayMove(Items, oldIndex, newIndex)
    }
    this.setItemOrder(newState.listItems)
    this.setState(newState)
  }

  getAxisSetting () {
    return "xy" || (this.props.vertimode ? "y" : "x")
  }

  render() {
    let height = this.props.height
    let axis = this.getAxisSetting()
    let itemProps = {
      transitionDuration:0,
      axis:axis,
      vertimode:this.props.vertimode
    }
    let tmpItems = this.state.listItems
    return (
        <SortableList pressDelay={dragDelay} helperClass={"intheair"} useWindowAsScrollContainer={true} onSortEnd={this.onSortEnd} onSortStart={(_, e) => e.preventDefault()}>
          {
            tmpItems.map((v,i,a) => {
              return (<CategoryPageItem {...itemProps} maxChartItemHeight={this.state.maxChartItemHeight} halfsizemode={this.state.halfsizemode} item={v} index={i} id={v.id} key={v.id} height={height} exportMode={this.props.exportMode} showTitle={this.props.showTitle}/>)
            }, this)
          }
        </SortableList>
    )
  }

}