 import React from 'react'
import styled from "styled-components"
import {library} from "@fortawesome/fontawesome-svg-core"
import {fal} from "@fortawesome/pro-light-svg-icons"
import store from "store"
import 'react-dropdown/style.css';
 import Modal from 'react-bootstrap/Modal';
import download from "downloadjs";
 import ReactMarkdown from "react-markdown";

library.add(fal)

const ContentContainer = styled.div`
  font-family:Lato;
  font-weight:normal;
  font-size: 1rem;
  margin: 0 1rem;
  line-height: 1.4rem;
`
const FlexColumn = styled.div`
  display:flex;
  flex-direction:column;
`

const FlexRow = styled.div`
  margin-top:3px;
  display:flex;
  flex-direction:row;
  justify-content:flex-end;
  text-align: right;
  min-width: 25vw;
`

export default class MenuContent extends React.Component {

    constructor(props) {
        super(props);
        let sp = new URLSearchParams(window.location.search);
        this.state = {
            showSources: this.props.showSources
        }
        let boardid = this.state.boardid = sp.get("id");
        let boardkey = this.state.boardkey = sp.get("key");
        this.state.authkey = store.get(this.lsAccessKey(boardid, boardkey)) || ""
        this.storeNotificationValue = this.storeNotificationValue.bind(this)
        this.handleExportAll = this.handleExportAll.bind(this)
        this.handleExportChapter = this.handleExportChapter.bind(this)
        this.handleSources = this.handleSources.bind(this)
        this.mailButton = this.mailButton.bind(this)
        this.logoutButton = this.logoutButton.bind(this)
        this.sourcesButton = this.sourcesButton.bind(this)
        this.getStoreAccessKey = this.getStoreAccessKey.bind(this)
        this.getNotificationInterval = this.getNotificationInterval.bind(this)
        this.setNotificationInterval = this.setNotificationInterval.bind(this)
        this.state.notifications = this.getNotificationInterval()
    }

    lsAccessKey(boardid, boardkey) {
        return boardid + '_' + boardkey;
    }

    handleLogout() {
        store.clearAll()
        const cUrl = window.location.href
        window.location.href = cUrl
    }

    handleExportAll() {
        let fetchurl = process.env.REACT_APP_SMARBOARD_BASEURI + "/getExport"
        let vars = {
            "boardKey": this.state.boardkey,
            "boardId": this.state.boardid,
            "authKey": this.state.authkey
        }
        const params = []
        for (let key in vars) {
            params.push(encodeURIComponent(key) + "=" + encodeURIComponent(vars[key]))
        }
        let fetchparams = params.join("&")
        fetchurl = fetchurl + "?" + fetchparams
        let filename = ""
        fetch(fetchurl)
            .then(response => {
                filename = response.headers.get("content-disposition")
                return response.blob()
            }).then(blob => {
                download(blob, filename, "application/pptx")
            }).catch((err) => console.error(err))
    }

    handleExportChapter() {
        let fetchurl = process.env.REACT_APP_SMARBOARD_BASEURI + "/getExport"
        let vars = {
            "categoryId": this.props.category.categoryid,
            "boardKey": this.state.boardkey,
            "boardId": this.state.boardid,
            "authKey": this.state.authkey
        }
        const params = []
        for (let key in vars) {
            params.push(encodeURIComponent(key) + "=" + encodeURIComponent(vars[key]))
        }
        let fetchparams = params.join("&")
        fetchurl = fetchurl + "?" + fetchparams
        let filename = ""
        fetch(fetchurl)
            .then((response) => {
                filename = response.headers.get("content-disposition")
                return response.blob()
            }).then((blob) => {
                download(blob, filename, "application/zip")
            }).catch((err) => console.error(err))
    }

    storeNotificationValue(e) {
        let vars = {
            "val": parseInt(e.target.value, 10),
            "id": this.state.boardid,
            "key": this.state.boardkey,
            "accesskey": this.state.authkey
        }
        this.setNotificationInterval(vars.val)
        fetch(process.env.REACT_APP_SMARBOARD_BASEURI + "/updateNotifications", {
            method: "POST",
            "mode": "cors",
            "headers": {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(vars)
        })
    }
    replaceMd(markdown) {
        return markdown.replace(/\r/g,"\n").replace(/\n\n/g, "\n").replace(/\n/g, "\n\n")
    }
    getStoreAccessKey() {
        return this.lsAccessKey(this.state.boardid, this.state.boardkey) + "notification";
    }
    getNotificationInterval() {
        const storevalue = parseInt(store.get(this.getStoreAccessKey()))
        return storevalue
    }
    setNotificationInterval(i) {
        store.set(this.getStoreAccessKey(),i)
        const newState = {...this.state, notifications:i}
        this.setState(newState)
    }

    notificationOptions() {
        const hoursInDay = 24
        return (
            <select value={this.state.notifications} className={"hoverPointer selectHeader"} id={"mailinterval"} onChange={this.storeNotificationValue}>
                <option className={"dropdownOption"} value={hoursInDay}>Maximal 1x Täglich</option>
                <option className={"dropdownOption"} value={hoursInDay * 2}>Maximal alle 2 Tage</option>
                <option className={"dropdownOption"} value={hoursInDay * 7}>Maximal wöchentlich</option>
                <option className={"dropdownOption"} value={hoursInDay * 30}>Maximal alle 30 Tage</option>
                <option className={"dropdownOption"} value={"0"}>keine Mails</option>
            </select>
        )
    }

    handleSources() {
        this.setState({showSources: !this.state.showSources})
    }

    render() {
        const divider = (
          <FlexRow>
              <FlexColumn>
                  <hr />
              </FlexColumn>
          </FlexRow>
        )
        const NutzungsbedingungenButton = (
          <FlexRow>
              <FlexColumn>
                  <button id={"nutzungsbedingungenbutton"} className={"exportbutton"}
                          onClick={this.props.displayTerms}>Nutzungsbedingungen
                  </button>
              </FlexColumn>
          </FlexRow>

        )
        let ExportChapter = (
            <FlexRow style={{paddingBottom: "3px", marginTop: "5px"}}>
                <FlexColumn>
                    <button id={"exportchapbutton"} className={"exportbutton hoverPointer"} onClick={this.handleExportChapter}>KAPITEL EXPORTIEREN</button>
                </FlexColumn>
            </FlexRow>
        )
        let ExportElements
        if (this.props.category) {
            ExportElements = (
                <FlexRow style={{marginTop: "5px"}}>
                    <FlexColumn>
                        <button id={"exportallbutton"} className={"exportbutton hoverPointer"}
                                onClick={this.handleExportAll}>ALLES EXPORTIEREN
                        </button>
                    </FlexColumn>
                </FlexRow>
            )
        }
        else {
            ExportElements = (
                <FlexRow style={{marginTop: "20px"}}>
                    <FlexColumn>
                        <button id={"exportallbutton"} className={"exportbutton hoverPointer"}
                                onClick={this.handleExportAll}>ALLES EXPORTIEREN
                        </button>
                    </FlexColumn>
                </FlexRow>
            )
        }
        let SourcesButton = (
            <button id={"sourcesbutton"} className={"exportbutton hoverPointer"}
                    onClick={this.handleSources}>QUELLEN
            </button>
        )
        let SourcesElement = (
            <FlexRow>
                <FlexColumn>{SourcesButton}</FlexColumn>
            </FlexRow>
        )
        let Sources = <FlexRow></FlexRow>
        if (this.state.showSources) {
            let sourceList = []
            this.props.sources.sort((o0, o1)=>{
                let out = 0
                if(o0.name > o1.name) {
                    out = 1
                }
                if(o0.name < o1.name) {
                    out = -1
                }
                return out
            }).reverse()
            for (let i in this.props.sources) {
                let sourceRow = (
                    <FlexRow style={{justifyContent: "flex-start"}} key={this.props.sources[i].name}>
                        <FlexColumn style={{justifyContent: "flex-start"}}>
                            <FlexRow style={{textAlign: "left", justifyContent: "flex-start"}}><ReactMarkdown children={this.replaceMd(this.props.sources[i].description)}/></FlexRow>
                        </FlexColumn>
                    </FlexRow>
                )
                sourceList.push(sourceRow)
            }
            Sources = (
                <FlexRow style={{maxWidth: "801px", justifyContent:"center"}}>
                    <FlexColumn  style={{padding: "5px"}}>
                        {sourceList}
                    </FlexColumn>
                </FlexRow>
            )
        }
        const ModalElement = (
            <Modal show={this.state.showSources} onHide={this.handleSources} scrollable={true} size={"xl"}>
                <Modal.Header closeButton>
                    <Modal.Title>Quellen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FlexColumn>
                        {Sources}
                    </FlexColumn>
                </Modal.Body>
            </Modal>
        )
        if (!this.props.category) {
            ExportChapter = ""
        }
        return (
            <ContentContainer>
                <FlexColumn style={{paddingRight: "90px", paddingBottom: "20px"}}>
                    {SourcesElement}
                    {this.mailButton()}
                    {ExportChapter}
                    {ExportElements}
                    {NutzungsbedingungenButton}
                    {divider}
                    {this.logoutButton()}
                </FlexColumn>
                {ModalElement}
            </ContentContainer>
        )
    }

    sourcesButton() {

    }
    logoutButton(){
        let LogoutButton = <button id={"logoutbutton"} className={"hoverPointer"} onClick={this.handleLogout}>LOGOUT</button>
        let logoutElements = (
          <FlexRow style={{marginTop: "10px"}}>
              <FlexColumn>{LogoutButton}</FlexColumn>
          </FlexRow>
        )
      return logoutElements
    }
    mailButton() {
        return (
          <FlexRow>
              <FlexColumn>
                  <FlexRow style={{paddingBottom: "3px"}}>
                      BENACHRICHTIGUNGEN
                  </FlexRow>
                  <FlexRow style={{paddingBottom: "10px"}}>
                      {this.notificationOptions()}
                  </FlexRow>
              </FlexColumn>
          </FlexRow>
        )

    }

}
