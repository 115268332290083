import React from 'react';
import {
  ManovaLogo,
  HeaderBackgroundColor,
  HeaderFontColor,
  SmartboardLogoWithText,
  Headline,
  MainFontColor,
  LinkHoverColor,
  minWidthForSingleLayout as minWidth
} from "./globalimports/SmartboardConfiguration"
import styled from "styled-components"


import SmartboardFooterBar from "./SmartboardFooterBar"
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image"

const NoBr = styled.p`
`

const ColumnText = styled.div`
  column-width: 250px;
  column-count: 3;
  text-align: left;
`

const HeaderBar = styled.div`
  background-color:${HeaderBackgroundColor};
  display:flex;
  justify-content:space-between;
  max-height:80px;
  color:${HeaderFontColor};
  padding-bottom:8px;
  padding-top:6px;
  padding-right:${(props) => {
    if (props.mobile) {
      return 0;
    } else {
      return 2.5;
    }
  }}vw;
  padding-left:2.5vw;
  position:sticky;
  top:0;
  z-index:5;
`

const TextHeaderContent = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:stretch;
  height:100%;
  margin-left:20px;
  text-align:center;
`

const PasswordInput = styled.input`
  background-color:white;
  outline:none;
  border-width:0px 0px 2px 0px;
  border-color:${LinkHoverColor};
  padding: .5rem;
  font-family:Lato;
  font-size:1.1rem;
  width:100%;
  color:${LinkHoverColor};
`

const LoginButton = styled.input`
  background-color:rgba(0,0,0,0);
  color:${MainFontColor};
  font-family:Lato;
  font-size:larger;
  font-weight:bold;
  font-size:16px;
  border-style:solid;
  border-color:black;
  margin-top:10px;
  padding: 0 1.2em;
`

const LargerHeadline = styled(Headline)`
  font-size:xx-large;
  text-align:left;
`

const SmallerText = styled.div`
  font-size:small;
  text-align:justify;
  margin-bottom:1em;
`
export default class LoginForm extends React.Component {
  //TODO this needs to be refactored to use TextPage
  constructor (props) {
    super(props)
    this.state = {
      textmode: false,
      halfsizemode: (window.innerWidth < (minWidth))
    }
    this.onResize = this.onResize.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    window.addEventListener("resize", this.onResize)
  }

  render() {
    let borderFormat = {}
    if (!this.state.halfsizemode) {
      borderFormat.paddingLeft = borderFormat.paddingRight = "2.5vw"
      borderFormat.backgroundColor = HeaderBackgroundColor
    } else {
      borderFormat.padding = borderFormat.margin = "0vw"
    }
    return <div style={{backgroundColor: "whitesmoke"}}>
      {this.loginPageHeader()}
      <div style={borderFormat}>{this.bsLoginForm()}</div>
      <SmartboardFooterBar/>
    </div>
  }

  bsLoginForm() {
    return (<Container fluid className={"h-100 d-flex align-items-stretch"} style={{minHeight: "80vh", backgroundColor: "whitesmoke", fontFamily: "Lato"}}>
      <Row noGutters className={"align-items-stretch h-100"} style={{"minHeight": "80vh"}}>
        <Col md={{span: 4}} className={"d-none align-items-center d-lg-flex"}>
          {this.bsLoginImage()}
        </Col>
        <Col md={{span: 8}} style={{"minWidth": "200px"}}>
          {this.loginInputs()}
        </Col>
      </Row>
    </Container>)
  }

  bsLoginImage() {
    let imageUrl = process.env.PUBLIC_URL + "/ic/ipad-landingpage-transparent-ohnerahmen.png"
    return (<Image fluid src={imageUrl}></Image>)
  }

  bsLoginTextHeader() {
    return (
      <TextHeaderContent>
        {this.getColumnInfoText()}
      </TextHeaderContent>
    )
  }

  loginInputs() {
    const textContent = this.getSplittedColumInfoText()
    //xs, sm, md, lg, xl
    let newLayout = (<div className={"h-100 col-xl-10 col-12 d-flex flex-column justify-content-center"}>
      <Row>
        <Col xs={12} md={12} lg={12} xl={4}>
          <LargerHeadline>Smartboard<br/>Seilbahnen</LargerHeadline>
          <SmallerText>Bitte geben Sie Ihre Zugangsdaten ein.</SmallerText>
        </Col>
        <Col xs={12} md={12} lg={12} xl={4}>
          <form style={{width:"100%"}} onSubmit={this.props.onSubmit}>
            <PasswordInput placeholder={"Passwort"} type={"password"} id={"password"} onChange={this.props.onChange}/><br/>
            <LoginButton type={"submit"} value={"LOGIN"}/>
            {this.props.message}
          </form>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={12} xl={4}>{textContent[0]}</Col>
        <Col xs={12} md={12} lg={12} xl={4}>{textContent[1]} {textContent[2]}</Col>
        <Col xs={12} md={12} lg={12} xl={4}>{textContent[3]}</Col>
      </Row>
    </div>)
    return newLayout
  }

  loginPageHeader() {
    if (this.state.halfsizemode) {
      return (
        <HeaderBar mobile={true}>
          <ManovaLogo/>
        </HeaderBar>
      )
    } else {
      return (
        <HeaderBar>
          <ManovaLogo/>
          <SmartboardLogoWithText/>
        </HeaderBar>
      )
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize)
  }

  onResize(e) {
    let newState = {}
    newState.halfsizemode = (window.innerWidth < (minWidth))
    this.setState(newState)
  }

  getColumnInfoText() {
    return (<ColumnText> Das Smartboard Seilbahnen ist eine Zusammenführung wichtiger Daten und Fakten zu den Seilbahnen. Alle Fakten sind themenweise geordnet und zeigen immer die aktuellsten verfügbaren Zahlen. Die Nutzung des Smartboards ist auch für Mobilgeräte optimiert. Zu allen Inhalten gibt es kurze Informationstexte und es kann bei Berechtigung auf dahinter liegende Reports bzw. Quellen zugegriffen werden. Alle Grafiken sind exportierbar, die korrekte Zitierweise lautet: <NoBr>WEBMARK Seilbahnen. Smartboard</NoBr><br/>Alle Bezeichnungen sind geschlechtsneutral zu verstehen. Aus Gründen der besseren Lesbarkeit wird bei Personenbezeichnungen die männliche Form verwendet, beinhaltet aber keine Wertung.</ColumnText>)
  }
  getSplittedColumInfoText() {
    return [
      "Das Smartboard Seilbahnen ist eine Zusammenführung wichtiger Daten und Fakten zu den Seilbahnen. Alle Fakten sind themenweise geordnet und zeigen immer die aktuellsten verfügbaren Zahlen. Die Nutzung des Smartboards ist auch für Mobilgeräte optimiert. Zu allen Inhalten gibt es",
      "kurze Informationstexte und es kann bei Berechtigung auf dahinter liegende Reports bzw. Quellen zugegriffen werden. Alle Grafiken sind exportierbar, die korrekte Zitierweise lautet:",
      (<NoBr>WEBMARK Seilbahnen. Smartboard</NoBr>),
      "Alle Bezeichnungen sind geschlechtsneutral zu verstehen. Aus Gründen der besseren Lesbarkeit wird bei Personenbezeichnungen die männliche Form verwendet, beinhaltet aber keine Wertung."
    ]
  }
}
