/**
 * theme für charts, änderungen mit admins absprechen
 * **/
function styleInject (css, ref) {
  if (ref === void 0) {
    ref = {}
  }
  let insertAt = ref.insertAt

  if (!css || typeof document === "undefined") {
    return
  }

  let head = document.head || document.getElementsByTagName("head")[0]
  let style = document.createElement("style")
  style.type = "text/css"

  if (insertAt === "top") {
    if (head.firstChild) {
      head.insertBefore(style, head.firstChild)
    } else {
      head.appendChild(style)
    }
  } else {
    head.appendChild(style)
  }

  if (style.styleSheet) {
    style.styleSheet.cssText = css
  } else {
    style.appendChild(document.createTextNode(css))
  }
}

let css = "@import url('https://fonts.googleapis.com/css2?family=Inria+Serif:wght@700&family=Lato:wght@400;700&display=swap');"
styleInject(css)

/*
WEBMARK Smartboard - Theme
*/

/* jshint ignore:end */
let themeObject = {
  name: "smart",
  theme: {
    base: {
      chart: {
        applyCssTransform: "1", // plot customization
        paletteColors: "#007B45, #70B4A0, #141C4B, #9FCDFF, #588DDB, #FF8C5A, #FFC6A9, #291D99, #127289, #C2E5E1",
        showShadow: "0",
        showPlotBorder: "0",
        usePlotGradientColor: "0",
        showValues: "0",
        useRoundEdges: "0",
        use3DLighting: "0",

        // chart and canvas background customization
        bgColor: "#FFFFFF",
        canvasBgAlpha: "0",
        bgAlpha: "100",
        showBorder: "0",
        showCanvasBorder: "0",
        alignCaptionWithCanvas: "0",

        // axis and div line customization
        showAlternateHGridColor: "0",
        showAlternateVGridColor: "0",
        divLineColor: "#E3E3E3",
        showXAxisLine: "0",
        yAxisNamePadding: "15",
        sYAxisNamePadding: "15",
        xAxisNamePadding: "15",
        captionPadding: "15",
        xAxisNameFontColor: "#141C4B",
        yAxisNameFontColor: "#141C4B",
        sYAxisNameFontColor: "#141C4B",
        yAxisValuesPadding: "15",
        labelPadding: "10",
        transposeAxis: "1",

        // tooltip customization
        toolTipBgColor: "#FFFFFF",
        toolTipPadding: "6",
        toolTipBorderColor: "#E3E3E3",
        toolTipBorderThickness: "1",
        toolTipBorderAlpha: "100",
        toolTipBorderRadius: "2",
        toolTipSepChar: " | ",

        // font and text size customization
        baseFont: "Lato",
        baseFontColor: "#141C4B",
        baseFontSize: "12",
        xAxisNameFontBold: "0",
        yAxisNameFontBold: "0",
        sYAxisNameFontBold: "0",
        xAxisNameFontSize: "12",
        yAxisNameFontSize: "12",
        sYAxisNameFontSize: "12",
        captionFontSize: "18",
        captionFont: "Inria Serif",
        subCaptionFontSize: "12",
        subCaptionFont: "Lato", //captionFontBold: "1",
        subCaptionFontBold: "0",
        subCaptionFontColor: "#6d728e",
        valueFontColor: "#131024",
        valueFont: "Lato",

        // caption customization
        captionAlignment: "center",

        // legend customization
        drawCustomLegendIcon: "1",
        legendShadow: "0",
        legendBorderAlpha: "0",
        legendBorderThickness: "0",
        legendItemFont: "Lato",
        legendItemFontColor: "#141c4b",
        legendIconBorderThickness: "0",
        legendBgAlpha: "0",
        legendItemFontSize: "12",
        legendCaptionFontColor: "#6d728e",
        legendCaptionFontSize: "12",
        legendCaptionFontBold: "0",
        legendScrollBgColor: "#FFF",
        alignLegendWithCanvas: "1", //3.10.0 Feature, aber falls wir mal upgraden

        // cross line customization
        crossLineAnimation: "1",
        crossLineAlpha: "100",
        crossLineColor: "#F1F1F1",

        // hover effect
        showHoverEffect: "1",
        plotHoverEffect: "1",
        plotFillHoverAlpha: "90",
        barHoveralpha: "90",

        // number scaling and formatting
        numberScaleValue: "1000,1000,1000",
        numberScaleUnit: " Tsd., Mio., Mrd.",
        DecimalSeparator: ",",
        ThousandSeparator: ".",
        decimals: "1",

        // chart margins
        //chartLeftMargin: "10",
        //chartRightMargin: "10",
        //chartTopMargin: "10",
        //chartBottomMargin: "10",

        // bar/column width/height
        maxBarHeight: "15",
        maxColWidth: "15"

      }, // trendlines
      trendlines: [{
        line: [{
          valueOnRight: "1",
          displayValue: "Ø",
          thickness: "1",
          color: "#588DDB"
        }]
      }]
    },
    column2d: {
      chart: {
        paletteColors: "#007B45",
        usePlotGradientColor: "0",
        plotGradientColor: "#007B45",
        plotFillAngle: "90",
      }
    },
    bar2d: {
      chart: {
        //might do something here
      }
    },
    doughnut2d: {
      chart: {
        startingAngle: "90",
        doughnutRadius: "80%",
        animateClockwise: "1",
        showLegend: "0",
        showLabels: "0",
        showValues: "0",
        centerLabelFont: "Lato",
        centerLabelFontSize: "24",
        centerLabelBold: "1",

      }
    },
    line: {
      chart: {
        drawCrossLine: "1"
      }
    },
    msline: {
      chart: {
        drawCrossLine: "1",
        showLegend: "1", //legendIconSides: "4",
        //legendIconBorderThickness: "2",
        //legendIconBgAlpha: "100",
        //legendBgAlpha: "100",
        //legendIconAlpha: "100",
        drawCustomLegendIcon: "0",
      }
    },
    "multiaxisline": {
      chart: {
        drawCustomLegendIcon: "0",
      }
    }

  }
}

// create a theme extension with the above theme object
let index = {
  extension: themeObject,
  name: "smart",
  type: "theme"
}

export default index
// Annotation Text Color workaround
//export const AnnotationColor = "#141C4B";
