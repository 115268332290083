import React from 'react';
import styled from "styled-components";
import {sortableContainer} from "react-sortable-hoc"
import {sortableElement} from "react-sortable-hoc"
import SmartboardFooterBar from "./SmartboardFooterBar"
import SmartboardHeaderBar from "./SmartboardHeaderBar"
import SmartboardChartWrapper from "./SmartboardChartWrapper"
import arrayMove from "array-move";
import store from "store"
import {
  HoverImage,
  categoryIconColor,
  dragBackgroundColor,
  dragDelayInMs as dragDelay,
  MainBackgroundColor,
  minWidthForSingleLayout as minWidth,
  ShadowColor
} from "./globalimports/SmartboardConfiguration"
import MenuContent from "./MenuContent"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import HtmlChart from './HtmlChart';
import replaceEmptyWithFallback from '../utils/replaceEmptyWithFallback';
import {newCheck} from '../utils/replaceEmptyWithFallback';

const ButtonListContainer = styled.div`
  margin:0px;
  margin-top:1.5rem;
  display:flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content:center;
  align-content:center;
  max-width:1920px;
`
const SButtonListContainer = sortableContainer(({children}) => {
  return <ButtonListContainer>{children}</ButtonListContainer>
})
const ButtonContainer = styled.div`
  display:flex;
  text-align: left;
  height:${(props) => {
  if (window.innerHeight < 800) {
    return 55
  } else {
    return 33
  }
}}vh;
  width:${(props) => {
  return props.width || 46
}}%;
  font-family:InriaSerif;
  font-weight:normal;
  font-size:1.2rem;
  background-color:white;
  margin: 1rem .5rem 0 .5rem;
  box-shadow: 7px 7px 5px ${ShadowColor};
  background-color:${dragBackgroundColor};
`

const SButtonContainer = sortableElement(({children, width}) => {
  return <ButtonContainer width={width} className={"DragButtonContainer"}>{children}</ButtonContainer>
})


const Button = styled.div`
  width:100%;
  height:100%;
  display:flex;
  flex-direction:column;
  align-items:stretch;
  align-content:stretch;
  flex-grow:4;
`
const ButtonChart = styled.div`
  width:100%;
  height:90%;
  background-color:white;
`
const ButtonHeadlineContainer = styled.div`
  flex-grow:1;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  border-bottom:2px solid ${MainBackgroundColor};
  align-items:center;
  background-color:white;
`
const ButtonIcon = styled.div`
font-size:1.5rem;
color:${categoryIconColor};
`

const ButtonText = styled.div`
  align-items:center;
  display:flex;
`
const NavContentWrapper = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
`
const p100 = "100%"
export default class CategorySelectionPage extends React.Component {
  constructor(props) {
    super(props)
    this.handleMouseHover = this.handleMouseHover.bind(this)
    let combinedCategories = Array.from(props.combinedCategories)
    let catTargetOrder = this.getCategoryOrder()
    let sortedCategories = []
    this.htmlRef = React.createRef()
    this.state = {htmlC : this.htmlRef.current}

    catTargetOrder.forEach((v, i, a) => {
      let foundIndex = combinedCategories.findIndex((fv, fi, fa) => {
        return fv.categoryid === v
      })
      if (foundIndex !== -1) {
        sortedCategories.push(combinedCategories.splice(foundIndex, 1).pop())
      }
    })
    sortedCategories = sortedCategories.concat(combinedCategories)
    this.state = {
      hovered: false,
      windowWidth: window.innerWidth,
      combinedCategories: sortedCategories
    }
    this.onResize = this.onResize.bind(this)
    this.onSortEnd = this.onSortEnd.bind(this)

    window.addEventListener("resize", this.onResize)
  }

  onResize() {
    this.setState({windowWidth: window.innerWidth})
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize)
  }
  /*
  componentDidMount() {
    console.log('CCCCCCCCCCCCderCCCCCCCCCCCCCCCCCCCCCCCC')
    console.log(this.buttonRef.current)
    if (this.buttonRef.current) {
      this.setState({myDimension: {
          "width": this.buttonRef.current.offsetWidth,
          "height": this.buttonRef.current.offsetHeight
        }})
    }
  }*/

  onSortEnd({oldIndex, newIndex}) {
    let categories = Array.from(this.state.combinedCategories)
    let newState = {
      ...this.state,
      combinedCategories:
        arrayMove(categories, oldIndex, newIndex)
    }
    this.setCategoryOrder(newState.combinedCategories)
    this.setState(newState)
  }

  setCategoryOrder(categories) {
    let categoryIds = categories.map((v, i, a) => v.categoryid)

    store.set(this.getOrderStorageName(), JSON.stringify(categoryIds))
  }

  getCategoryOrder() {
    return JSON.parse(store.get(this.getOrderStorageName()) || "[]")
  }

  getOrderStorageName() {
    return this.props.boardId + '-order-boardid'
  }

  handleMouseHover() {
    this.setState(this.toggleHoverState);
  }

  toggleHoverState(state) {
    return {
      hovered: !state.hovered
    };
  }

  render() {
    let tmpItems = this.state.combinedCategories//.slice(0,3)
    let menuContent = (<MenuContent category={this.props.category} showSources={false} sources={this.props.sources} displayTerms={this.props.displayTerms} />)
    return (<NavContentWrapper>
        <SmartboardHeaderBar menuContent={menuContent} displayname={this.props.displayname}/>
        <SButtonListContainer menuContent={menuContent} pressDelay={dragDelay} helperClass={"intheair"} useWindowAsScrollContainer={true} onSortEnd={this.onSortEnd} onSortStart={(_, e) => e.preventDefault()}>
          {
            tmpItems.map((v, i, a) => {
              let buttonContent
              const {titlechartdata, hasNull} = replaceEmptyWithFallback(v.titlechartdata)
              let cd = titlechartdata

              delete cd.height
              delete cd.width
              cd.height = cd.width = p100
              let hideLegend = false
              if (this.state.windowWidth < 900) {
                hideLegend = true
              }
              let cRef
              if (cd["__html"]) {

                buttonContent =(<HtmlChart hasNull={hasNull} categorytitel={v.title} html={cd}/>)
              } else {
                cRef=React.createRef()
                buttonContent = (
                  <SmartboardChartWrapper containerRef={cRef} fcData={cd} hideLegend={hideLegend} hasNull={hasNull} categorytitel={v.title}/>
                  )
              }
              let width = 46
              if (this.state.windowWidth <= (minWidth)) {
                width *= 2
              }
              const icon = v.icon
              const actualIcon = icon ? (<FontAwesomeIcon icon={["fas", icon]}/>) : "";
              let arrowIcon = <svg className={"hoverGreen"} xmlns="http://www.w3.org/2000/svg" width="19.042" height="18.868" viewBox="0 0 19.042 18.868">
                <path id="pfeil-rechts-weiter" d="M962.553,781.8l-4.476,7.328h12.285v4.211H958.076l4.476,7.329h-5.046l-6.187-9.433,6.187-9.433Z" transform="translate(970.362 800.668) rotate(180)"/>
              </svg>

              return (<SButtonContainer width={width} index={i} key={v.categoryid} catCount={a.length}>
                <Button className={"hoverPointer"} onClick={() => this.props.clickFeedback(v.categoryid)} key={v.categoryid}>
                  <ButtonHeadlineContainer>
                    <ButtonIcon>&nbsp;&nbsp;{actualIcon}</ButtonIcon>
                    <ButtonText>{v.title}&nbsp;&nbsp;{arrowIcon}&nbsp;</ButtonText>
                  </ButtonHeadlineContainer>
                  <ButtonChart ref={this.htmlRef} className={"fcFIX"}>{buttonContent}</ButtonChart>
                </Button>
              </SButtonContainer>);
            })
          }</SButtonListContainer>
        <SmartboardFooterBar margin={"margin-top:3rem;"}/>
      </NavContentWrapper>
    );
  }
}
