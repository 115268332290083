import React from 'react';
import CategoryPage from "./CategoryPage"
import FusionCharts from "fusioncharts"
import charts from "fusioncharts/fusioncharts.charts"
import widgets from "fusioncharts/fusioncharts.widgets"
import fcTheme from "fusioncharts/themes/es/fusioncharts.theme.fusion"
import CategorySelectionPage from "./CategorySelectionPage"
FusionCharts.options.creditLabel = false;
charts(FusionCharts);
widgets(FusionCharts);
FusionCharts.addDep(fcTheme)
/**
 * this renders the list of buttons
 * */
export default
class SmartboardList extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      "combinedCategories": props.combinedCategories
    }
    this.setExtend=this.setExtend.bind(this);
    this.onSetSidebarOpen=this.onSetSidebarOpen.bind(this)
  }
  onSetSidebarOpen(o) {
    this.setState({sidebarOpen:o})
  }
  renderUI() {
    const extended = this.state.extended;
    if (extended) {
      // TODO - change so only one extended category
      let extendedCategory = this.state.combinedCategories.find((v,i,a)=> {
        return v.categoryid === extended
      }, this)
      return (

        <CategoryPage
          displayname={this.props.displayname}
          boardId={this.props.boardId}
          clickFeedback={this.setExtend}
          category={extendedCategory}
          exportMode={this.props.exportMode}
          sources={this.props.sources}
          displayTerms={this.props.displayTerms}
        />
      )
    }
    else {
      //render categories
      return (
        <div>
          <CategorySelectionPage
            displayname={this.props.displayname}
            boardId={this.props.boardId}
            combinedCategories={this.state.combinedCategories}
            clickFeedback={this.setExtend}
            sources={this.props.sources}
            displayTerms={this.props.displayTerms}
          />
        </div>
      )
    }
  }

  render() {
    if (! this.props.exportMode) {
      return this.renderUI()
    } else {
      return this.renderExport()
    }
  }

  renderExport() {
    let a = this.state.combinedCategories.flatMap((category) => { return category.list })
    /* if (! this.props.showTitle) {
      a.map(function (x) {
        if (x.type === "fusioncharts") {
          (x.data.dataSource || x.data).chart.caption = ""
        }
        if (x.type === "combo") {
          //x.data.map(this)
          for (let y of x.data) {
            if (y.type === "fusioncharts") {
              (y.data.dataSource || y.data).chart.caption = ""
            }
          }
        }
        return x
      })
    } */
    let category = {
      ...this.state.combinedCategories[0],
      title: "Export",
      list: a
    }
    return (
        <div className={this.props.className}>
        <CategoryPage
            boardId={this.props.boardId}
            clickFeedback={()=>{}}
            category={category}
            exportMode={this.props.exportMode}
            showTitle={this.props.showTitle}
            sources={this.props.sources}
        />
        </div>
    )
  }

  /**
   * set the State to the currently selected category and merge the List of Items from the server and client
   *
   * */
  setExtend = (extended) => {
    if (extended) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      this.setState({"extended":extended})
    }
    else {
      this.setState({"extended":undefined})
    }
  }

}
